import { IOperatorLandingContext } from '@/shared/model/raptor/messaging/support/operator-landing-context.model';
import axios, { AxiosError, AxiosResponse } from 'axios';

export interface IPublishPayload {
  context?: IOperatorLandingContext;
  response?: AxiosResponse | AxiosError;
  getEvaluationContext(): { [k: string]: any };
}

export class PublishPayload implements IPublishPayload {
  constructor(public context?: any, public response?: AxiosResponse | AxiosError) {}

  public getEvaluationContext(): { [k: string]: any } {
    if (axios.isAxiosError(this.response)) {
      return {
        payload: {
          message: this.context.getInnerMessage(),
          response: {
            ...this.response.response,
            data: JSON.parse(this.response.response.config.data),
          },
        },
      };
    } else {
      return {
        payload: {
          message: this.context.getInnerMessage(),
          response: {
            ...this.response,
          },
        },
      };
    }
  }
}
