var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "pa-4 text-center fill-height", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { attrs: { align: "center", justify: "center" } },
        [
          _vm._l(_vm.items, function (item, i) {
            return [
              _c(
                "v-col",
                { key: i, attrs: { cols: "12", md: "4" } },
                [
                  _c(
                    "v-hover",
                    [
                      _c("v-card", [
                        _c(
                          "a",
                          { attrs: { href: item.url, target: "_blank" } },
                          [
                            _c(
                              "v-img",
                              { attrs: { src: item.img, height: "175px" } },
                              [
                                _c(
                                  "v-card-title",
                                  {
                                    staticClass:
                                      "ma-0 text-h4 white--text font-weight-bold text-center fill-height align-content-center",
                                  },
                                  [
                                    _c(
                                      "v-row",
                                      {
                                        attrs: {
                                          align: "center",
                                          justify: "center",
                                        },
                                      },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "mt-4 subheading text-left",
                                            staticStyle: {
                                              "-webkit-text-stroke-width":
                                                "0.05rem",
                                              "-webkit-text-stroke-color":
                                                "black",
                                            },
                                            style: `color:${item.color}`,
                                          },
                                          [
                                            _c("b", [
                                              _vm._v(
                                                _vm._s(_vm.$t(item.title))
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }