import * as yakovlev from '@/shared/config/store/raptor/mutation-types/yakovlev-store-types';
import * as progress from '@/shared/config/store/raptor/mutation-types/progress-store-types';
// import * as notification from '@/shared/config/store/raptor/mutation-types/notifications-types'
import * as operatorLanding from '@/shared/config/store/raptor/mutation-types/operator-landing-types';
import * as bottomSheetStore from '@/shared/config/store/raptor/mutation-types/bottom-sheet-store-types';
import * as workCenterDeviceStore from '@/shared/config/store/raptor/mutation-types/work-center-device-store-types';
import * as workCenterAccountStore from '@/shared/config/store/raptor/mutation-types/work-center-account-store-types';
import * as workCenterOperatorStore from '@/shared/config/store/raptor/mutation-types/work-center-operator-store-types';

export const progressStoreMutationTypes = {
  active: `progressStore/${progress.active}`,
  indeterminate: `progressStore/${progress.indeterminate}`,
  color: `progressStore/${progress.color}`,
};

export const workCenterAccountStoreMutationTypes = {
  authRequest: `workCenterAccountStore/${workCenterAccountStore.authRequest}`,
  authSuccess: `workCenterAccountStore/${workCenterAccountStore.authSuccess}`,
  authError: `workCenterAccountStore/${workCenterAccountStore.authError}`,
  logout: `workCenterAccountStore/${workCenterAccountStore.logout}`,
};

// Deprecated alertService
// export const notificationsMutationTypes = {
//     addAlert: `notifications/${notification.addAlert}`,
//     removeAlert: `notifications/${notification.removeAlert}`,
// }

export const workCenterOperatorStoreMutationTypes = {
  fetchData: `workCenterOperatorStore/${workCenterOperatorStore.fetchData}`,
  update: `workCenterOperatorStore/${workCenterOperatorStore.update}`,
  authRequest: `workCenterOperatorStore/${workCenterOperatorStore.authRequest}`,
  authSuccess: `workCenterOperatorStore/${workCenterOperatorStore.authSuccess}`,
  authError: `workCenterOperatorStore/${workCenterOperatorStore.authError}`,
  logout: `workCenterOperatorStore/${workCenterOperatorStore.logout}`,
};

export const workCenterDeviceStoreMutationTypes = {
  fetchData: `workCenterDeviceStore/${workCenterDeviceStore.fetchData}`,
  start: `workCenterDeviceStore/${workCenterDeviceStore.start}`,
  subscribe: `workCenterDeviceStore/${workCenterDeviceStore.subscribe}`,
  messageReceived: `workCenterDeviceStore/${workCenterDeviceStore.messageReceived}`,
  update: `workCenterDeviceStore/${workCenterDeviceStore.update}`,
};

export const operatorLandingStoreMutationTypes = {
  fetchData: `operatorLandingStore/${operatorLanding.fetchData}`,
  manufacturingPlan: `operatorLandingStore/${operatorLanding.manufacturingPlan}`,
  loading: `operatorLandingStore/${operatorLanding.loading}`,
  workCenter: `operatorLandingStore/${operatorLanding.workCenter}`,
  rowData: `operatorLandingStore/${operatorLanding.rowData}`,
  printLabel: `operatorLandingStore/${operatorLanding.printLabel}`,
  linkDocumentToWorkOrder: `operatorLandingStore/${operatorLanding.linkDocumentToWorkOrder}`,
  linkDocumentToWorkOrderThenTrack: `operatorLandingStore/${operatorLanding.linkDocumentToWorkOrderThenTrack}`,
  updateCompletedQuantity: `operatorLandingStore/${operatorLanding.updateCompletedQuantity}`,
  complete: `operatorLandingStore/${operatorLanding.complete}`,
  completeWithoutOperationTrack: `operatorLandingStore/${operatorLanding.completeWithoutOperationTrack}`,
  skip: `operatorLandingStore/${operatorLanding.skip}`,
  completeAll: `operatorLandingStore/${operatorLanding.completeAll}`,
  resetAll: `operatorLandingStore/${operatorLanding.resetAll}`,
  skipAll: `operatorLandingStore/${operatorLanding.skipAll}`,
  resumeAll: `operatorLandingStore/${operatorLanding.resumeAll}`,
  setLinkDocumentTags: `operatorLandingStore/${operatorLanding.setLinkDocumentTags}`,
  willOverTrack: `operatorLandingStore/${operatorLanding.willOverTrack}`,
};

export const bottomSheetStoreMutationTypes = {
  sheet: `bottomSheetStore/${bottomSheetStore.sheet}`,
  connected: `bottomSheetStore/${bottomSheetStore.connected}`,
  disconnected: `bottomSheetStore/${bottomSheetStore.disconnected}`,
  reconnect: `bottomSheetStore/${bottomSheetStore.reconnect}`,
  set: `bottomSheetStore/${bottomSheetStore.set}`,
  reset: `bottomSheetStore/${bottomSheetStore.reset}`,
  remove: `bottomSheetStore/${bottomSheetStore.remove}`,
};

export const yakovlevStoreMutationTypes = {
  addMessage: `yakovlevStore/${yakovlev.addMessage}`,
  deleteMessage: `yakovlevStore/${yakovlev.deleteMessage}`,
  updateMessageStatus: `yakovlevStore/${yakovlev.updateMessageStatus}`,
  connectionOpened: `yakovlevStore/${yakovlev.connectionOpened}`,
  connectionClosed: `yakovlevStore/${yakovlev.connectionClosed}`,
  connectionError: `yakovlevStore/${yakovlev.connectionError}`,
  setConnection: `yakovlevStore/${yakovlev.setConnection}`,
  setError: `yakovlevStore/${yakovlev.setError}`,

  subscribeTopic: `yakovlevStore/${yakovlev.subscribeTopic}`,
  unsubscribeTopic: `yakovlevStore/${yakovlev.unsubscribeTopic}`,
  publishTopic: `yakovlevStore/${yakovlev.publishTopic}`,
  republishTopic: `yakovlevStore/${yakovlev.republishTopic}`,
};
export const yakovlevMqttMutations = [
  yakovlevStoreMutationTypes.subscribeTopic,
  yakovlevStoreMutationTypes.unsubscribeTopic,
  yakovlevStoreMutationTypes.publishTopic,
  yakovlevStoreMutationTypes.republishTopic,
];
