var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("go-left-drawer", {
        attrs: {
          resolveLocalPrefix: _vm.resolveLocalPrefix,
          items: _vm.authorizedItems,
        },
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.leftBubbleUp.apply(null, arguments)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }