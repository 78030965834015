export interface IResponseData {
  message: string;
}

export class ResponseData implements IResponseData {
  constructor(public message: string) {}
}

export interface IResponse {
  status: string;
  statusText: string;
  data: IResponseData;
}

export class Response implements IResponse {
  constructor(public status: string, public statusText: string, public data: IResponseData) {}
}

export class ResponseStatusError extends Error {
  private response: IResponse;

  constructor(message, messageStatus) {
    super(message);

    // Because we are extending a built-in class
    Object.setPrototypeOf(this, ResponseStatusError.prototype);

    this.name = 'ResponseStatusError';

    this.response = new Response(messageStatus.value, messageStatus.reasonPhrase, new ResponseData(message));
  }
}
