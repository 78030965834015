import { Store } from 'vuex';
import VueI18n from 'vue-i18n';

/**
 * Singleton class we have added below.
 */
export default class ApplicationContext {
  private static store: Store<any>;
  private static i18n: VueI18n;

  private constructor() {}

  public static setStore(store: Store<any>): void {
    ApplicationContext.store = store;
  }

  public static setI18n(i18n: VueI18n): void {
    ApplicationContext.i18n = i18n;
  }

  public static getStore(): Store<any> {
    return ApplicationContext.store;
  }

  public static getI18n(): VueI18n {
    return ApplicationContext.i18n;
  }
}
