import { yakovlevMqttMutations, yakovlevStoreMutationTypes } from '@/shared/config/store/raptor/mutation-types/types';
import { GenericMessage } from '@/shared/model/raptor/messaging/support/message/generic-message.model';

function isMqttMutation(mutationType) {
  return yakovlevMqttMutations.indexOf(mutationType) > -1;
}

function createWebSocket() {
  const url = new URL((location.protocol === 'http:' ? 'ws://' : 'wss://') + YAKOVLEV_WS_URL);
  if (location.protocol !== 'http:') url.port = '';

  console.debug(`WebSocket url '${url.toString()}'`);
  return new WebSocket(url.toString());
}

function createPlugin(socket) {
  return store => {
    // socket.onmessage('data', data => {
    //   console.log(data)
    //   store.commit('receiveData', data)
    // })

    // Vanilla websockets

    socket.onopen = () => {
      store.dispatch(yakovlevStoreMutationTypes.connectionOpened);
    };

    /**
     * The close event is fired when a connection with a WebSocket is closed, either by the server or by the client.
     * It could be due to various reasons like the server shutting down, network issues, or the client explicitly
     * closing the connection.
     */
    socket.onclose = () => {
      store.dispatch(yakovlevStoreMutationTypes.connectionClosed);
    };

    /**
     * The WebSocket.onmessage property is an EventHandler that is called when a message is received from the server.
     * It is called with a MessageEvent.
     * @param data
     */
    socket.onmessage = messageEvent => {
      const message = GenericMessage.FromMessageEvent(messageEvent);
      store.dispatch(yakovlevStoreMutationTypes.addMessage, message);
    };

    /**
     * The error event is fired when a connection with a WebSocket has been closed due to an error (some data couldn't
     * be sent for example). It could be due to network errors, server-side issues, or other problems preventing the
     * WebSocket from functioning correctly. The onerror event handler is useful for handling and logging errors that
     * occur during the WebSocket communication.
     */
    socket.onerror = error => {
      store.dispatch(yakovlevStoreMutationTypes.connectionError, error);
    };

    store.subscribe((mutation, state) => {
      if (!state.yakovlevStore.connected) {
        return;
      }

      if (isMqttMutation(mutation.type)) {
        socket.send(JSON.stringify(mutation.payload));
      }
    });

    // return store => {
    //   socket.on('stateChanged', (oldState, newState) => {
    //     if (oldState !== newState && newState !== 'Connected')
    //       store.dispatch('chat/connectionClosed');
    //     else store.dispatch('chat/connectionOpened');
    //   });
    //
    //   socket
    //       .start()
    //       .then(() => {
    //         store.dispatch('chat/connectionOpened');
    //       })
    //       .catch(err => {
    //         store.dispatch('chat/connectionError', err);
    //       });
    // };
  };
}

export default {
  createWebSocket,
  createPlugin,
};
