// import store from "@/store"
import { yakovlevStoreMutationTypes, bottomSheetStoreMutationTypes } from '@/shared/config/store/raptor/mutation-types/types';
import { PUBLISH } from '@/shared/raptor/messaging/support/mqtt-web-socket-headers';
// import { createI18n } from "@/i18n"
import ApplicationContext from '@/shared/raptor/context/application-context';
import messageHandlerSupport from '@/shared/raptor/messaging/support/message/message-handler-support';

export const MessageFlow = {
  /* Value in milliseconds */
  TIMEOUT: 2000,

  /* Number of attempts before quitting permanently */
  ATTEMPTS_BEFORE_QUITTING: 5,
};

export const DeliveryStatus = {
  /* The message was successfully delivered to the intended destination. */
  DELIVERED: 'delivered',

  /* Pending: Delivery of the message is being attempted or re-attempted. */
  PENDING: 'pending',

  /** Failed: The message was not delivered. */
  FAILED: 'failed',
};

class MessageFlowControl {
  private genericMessage;
  private deliveryStatus;
  private deliverySequence;

  constructor(genericMessage, deliverySequence) {
    this.genericMessage = genericMessage;
    this.deliveryStatus = DeliveryStatus.PENDING;
    this.deliverySequence = deliverySequence || 1;

    this.postConstruct();
  }

  postConstruct() {
    this.awaitForResponse();
  }

  awaitForResponse() {
    setTimeout(() => this.resend(this), MessageFlow.TIMEOUT);
  }

  resend(flowControl) {
    const deviceId = this.genericMessage.getDeviceCode();
    let status;

    if (flowControl.deliveryStatus === DeliveryStatus.DELIVERED) {
      status = ApplicationContext.getI18n().t('responseReceivedForMessage', { '0': this.genericMessage.getPayloadHeaderUuid() });
      console.debug(status);

      if (deviceId) {
        ApplicationContext.getStore()
          .dispatch(bottomSheetStoreMutationTypes.connected, { id: deviceId })
          .then(() => {
            console.debug(`Device ${deviceId} state set to connected.`);
          });
      }
      return;
    }
    const payload = {
      uuid: this.genericMessage.getPayloadHeaderUuid(),
      deliverySequence: this.deliverySequence,
      deliveryStatus: DeliveryStatus.FAILED,
    };
    ApplicationContext.getStore()
      .dispatch(yakovlevStoreMutationTypes.updateMessageStatus, payload)
      .then(() => {
        // Exceed the number of attempts
        if (this.deliverySequence > MessageFlow.ATTEMPTS_BEFORE_QUITTING) {
          status = ApplicationContext.getI18n().t('raptor.operatorLanding.messageFailedPermanentlyExceededTheNumberOfAttemptsLimit', {
            '0': this.genericMessage.getPayloadHeaderUuid(),
          });
          console.debug(status);
        } else {
          status = ApplicationContext.getI18n().t('raptor.operatorLanding.messageAttemptForWaitForTheResponseHasExpiredResendingMessage', {
            '0': this.genericMessage.getPayloadHeaderUuid(),
            '1': flowControl.deliverySequence,
          });
          console.debug(status);

          const mqttMessage = {
            topic: flowControl.genericMessage.headers[PUBLISH],
            message: JSON.parse(flowControl.genericMessage.payload),
          };
          ApplicationContext.getStore()
            .dispatch(yakovlevStoreMutationTypes.republishTopic, {
              componentType: messageHandlerSupport.getComponentType(flowControl.genericMessage),
              ...mqttMessage,
              flowControl: {
                sequence: flowControl.deliverySequence,
              },
            })
            .then(r => {
              console.debug(`Message ${r} republished.`);
            })
            .catch(error => {
              throw error;
            });
        }

        // Open bottom sheet
        if (deviceId) {
          ApplicationContext.getStore()
            .dispatch(bottomSheetStoreMutationTypes.disconnected, {
              id: deviceId,
              message: status,
            })
            .then(() => {
              console.debug(`Device ${deviceId} state set to disconnected.`);
            });
          ApplicationContext.getStore()
            .dispatch(bottomSheetStoreMutationTypes.sheet, true)
            .then(() => {
              console.debug(`Bottom sheet opened.`);
            });
        }
      });
  }
}
export { MessageFlowControl };
