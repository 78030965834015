export class ApplicationHeaders {
  constructor(public headers: { [k: string]: any }) {
    if (!headers.hasOwnProperty('timestamp')) {
      headers.timestamp = Date.now();
    } else if (headers.timestamp < 0) {
      delete headers.timestamp;
    }
    this.headers = headers;
  }
}
