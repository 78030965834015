import axios from 'axios';
import { Store } from 'vuex';
import VueRouter from 'vue-router';
import TranslationService from '@/locale/translation.service';
import LoginService from '@/account/work-center-authentication/login.service';
import { Authority } from '@/shared/security/authority';
import { workCenterAccountStoreMutationTypes, workCenterDeviceStoreMutationTypes } from '@/shared/config/store/raptor/mutation-types/types';

export default class AccountService {
  constructor(
    private store: Store<any>,
    private translationService: TranslationService,
    private cookie: any,
    private router: VueRouter,
    private loginService: LoginService
  ) {}

  public retrieveAccount(): Promise<boolean> {
    return new Promise(resolve => {
      /* SPE Begin
      axios
        .get<any>('api/account')
        .then(response => {
          this.store.commit('authenticate');
          const account = response.data;
          if (account) {
            this.store.commit('authenticated', account);
            if (this.store.getters.currentLanguage !== account.langKey) {
              this.store.commit('currentLanguage', account.langKey);
            }
            if (sessionStorage.getItem('requested-url')) {
              this.router.replace(sessionStorage.getItem('requested-url'));
              sessionStorage.removeItem('requested-url');
            }
          } else {
            this.store.commit('logout');
            if (this.router.currentRoute.path !== '/') {
              this.router.push('/');
            }
            sessionStorage.removeItem('requested-url');
          }
          this.translationService.refreshTranslation(this.store.getters.currentLanguage);
          resolve(true);
        })
        .catch(() => {
          this.store.commit('logout');
          resolve(false);
        });
      SPE End */
      this.loginService
        .introspect(this.cookie.get('token'))
        .then(response => {
          if (response.success) {
            // Rebuild build user object
            const accessToken = response.accessToken;
            const user = {
              name: `${accessToken.user.username}  ${accessToken.user.site}`,
              email: accessToken.user.email || '',
              data: { ...accessToken.user }, // Wrap additional data in a data property
            };
            // Commit auth success
            this.store.commit(workCenterAccountStoreMutationTypes.authSuccess, { token: accessToken.token, user });
          } else {
            // this.store.commit('logout');
            this.store.commit(workCenterAccountStoreMutationTypes.authError);
            if (this.router.currentRoute.path !== '/') {
              this.router.push('/');
            }
            sessionStorage.removeItem('requested-url');
          }
          this.translationService.refreshTranslation(this.store.getters.currentLanguage);
          resolve(true);
        })
        .then(() => {
          this.store
            .dispatch(workCenterDeviceStoreMutationTypes.fetchData, {
              workCenter: this.store.getters['workCenterAccountStore/user'].data.username,
              manufacturingSite: this.store.getters['workCenterAccountStore/user'].data.site,
            })
            .then(() => {
              resolve(true);
            });
        })
        .catch(() => {
          // this.store.commit('logout');
          this.store.commit(workCenterAccountStoreMutationTypes.authError);
          resolve(false);
        });
    });
  }

  public hasAnyAuthorityAndCheckAuth(authorities: any): Promise<boolean> {
    if (typeof authorities === 'string') {
      authorities = [authorities];
    }

    if (!this.authenticated || !this.userAuthorities) {
      // const token = this.cookie.get('JSESSIONID') || this.cookie.get('XSRF-TOKEN') // SPE
      const token = this.cookie.get('token'); // SPE
      if (!this.store.getters.account && !this.store.getters.logon && token) {
        return this.retrieveAccount().then(resp => {
          if (resp) {
            return this.checkAuthorities(authorities);
          }
          return Promise.resolve(false);
        });
      }
      return Promise.resolve(false);
    }

    return this.checkAuthorities(authorities);
  }

  public get authenticated(): boolean {
    // return this.store.getters.authenticated; // SPE
    return (
      this.store.getters['workCenterAccountStore/isLoggedIn'] &&
      // Required for restore vuex auth state on page refresh
      Object.keys(this.store.getters['workCenterAccountStore/user']).length !== 0
    );
  }

  public get userAuthorities(): any {
    // return this.store.getters.account?.authorities; // SPE
    return this.authenticated ? [Authority.USER, Authority.SHOP_FOOR] : [];
  }

  private checkAuthorities(authorities: any): Promise<boolean> {
    if (this.userAuthorities) {
      for (const authority of authorities) {
        if (this.userAuthorities.includes(authority)) {
          return Promise.resolve(true);
        }
      }
    }
    return Promise.resolve(false);
  }
}
