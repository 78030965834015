export const addMessage = 'addMessage';
export const deleteMessage = 'deleteMessage';
export const updateMessageStatus = 'updateMessageStatus';
export const connectionOpened = 'connectionOpened';
export const connectionClosed = 'connectionClosed';
export const connectionError = 'connectionError';
export const setConnection = 'setConnection';
export const setError = 'setError';

// Mqtt
export const subscribeTopic = 'subscribeTopic';
export const unsubscribeTopic = 'unsubscribeTopic';
export const publishTopic = 'publishTopic';
export const republishTopic = 'republishTopic';
