import axios from 'axios';

import { ISkippedOperationTrack } from '@/shared/model/skipped-operation-track.model';

// SPE Begin use /services/{service}/** pattern
// const baseApiUrl = 'api/skipped-operation-tracks';
const baseApiUrl = '/services/fargo/api/skipped-operation-tracks';
// SPE End /services/{service}/** pattern

export default class SkippedOperationTrackService {
  public find(id: number): Promise<ISkippedOperationTrack> {
    return new Promise<ISkippedOperationTrack>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieve(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public delete(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public create(entity: ISkippedOperationTrack): Promise<ISkippedOperationTrack> {
    return new Promise<ISkippedOperationTrack>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public update(entity: ISkippedOperationTrack): Promise<ISkippedOperationTrack> {
    return new Promise<ISkippedOperationTrack>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public partialUpdate(entity: ISkippedOperationTrack): Promise<ISkippedOperationTrack> {
    return new Promise<ISkippedOperationTrack>((resolve, reject) => {
      axios
        .patch(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
