import { Component, Inject, Vue } from 'vue-property-decorator';
import { AlertService, GoCountDownTimerComponent as GoCountDownTimer } from '@gv/ammo-grome';

const DISABLE_BOTTOM_SHEET_ROUTES = ['/', '/kiosk'];

@Component({
  components: {
    GoCountDownTimer,
  },
})
export default class RaptorBottomSheet extends Vue {
  @Inject('alertService')
  private alertService: () => AlertService;

  public get sheet() {
    return !DISABLE_BOTTOM_SHEET_ROUTES.includes(this.$route.path) && this.$store.getters['bottomSheetStore/sheet'];
  }

  public set sheet(value) {
    this.$store.dispatch('bottomSheetStore/sheet', value).catch(error => {
      this.alertService().showError(this, error);
    });
  }

  public get items() {
    return this.$store.getters['bottomSheetStore/items'];
  }

  public reconnect(id) {
    this.$store.dispatch(`${id}Store/reconnect`, {}).catch(error => {
      this.alertService().showError(this, error);
    });
  }
}
