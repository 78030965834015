import { Component, Emit, Inject, Prop, Provide, Vue } from 'vue-property-decorator';
import { AuroraService } from '@gv/ammo-astra';
import { AlertService, GoLeftDrawerComponent as GoLeft } from '@gv/ammo-grome';

@Component({
  components: {
    'go-left-drawer': GoLeft,
  },
})
export default class LeftDrawer extends Vue {
  @Provide('auroraService')
  private auroraService = () => new AuroraService();

  @Inject('alertService')
  private alertService!: () => AlertService;

  @Emit('click')
  // eslint-disable-next-line
  leftBubbleUp(e: any) {}

  @Prop(String) menu!: string;

  @Prop(String) resolveLocalPrefix!: string;

  // eslint-disable-next-line
  private items: Array<{ [k: string]: any }> = []

  created(): void {
    this.retrieve();
  }

  private get authorizedItems() {
    return this.items;
  }

  public get authenticated(): boolean {
    return this.$store.getters.authenticated;
  }

  public goToOperators() {
    this.$router.push({ name: 'ErpEmployeesTracker' });
  }

  public retrieve(): void {
    this.auroraService()
      .sideBarMenu(this.menu)
      .then(res => {
        this.items = res;
      })
      .catch(error => {
        this.alertService().showHttpError(this, error.response);
      });
  }
}
