export interface ILinkDocumentToTag {
  id?: number;
  updateCounter?: number | null;
  entryType?: number;
  document?: string;
  entryLine?: number;
  entrySequence?: number;
  tagNumber?: string;
  sourceType?: string;
  source?: string;
  evaluationContext?: string;
  createdAt?: Date | null;
  updatedAt?: Date | null;
  uuid?: string | null;
  createdByUser?: string | null;
  updatedByUser?: string | null;
}

export class LinkDocumentToTag implements ILinkDocumentToTag {
  constructor(
    public id?: number,
    public updateCounter?: number | null,
    public entryType?: number,
    public document?: string,
    public entryLine?: number,
    public entrySequence?: number,
    public tagNumber?: string,
    public sourceType?: string,
    public source?: string,
    public evaluationContext?: string,
    public createdAt?: Date | null,
    public updatedAt?: Date | null,
    public uuid?: string | null,
    public createdByUser?: string | null,
    public updatedByUser?: string | null
  ) {}
}
