/**
 * Formery validation/support/modules/operator-landing.js
 * Formery validation/support/validation-utils.ts
 */
import VueI18n from 'vue-i18n';
import * as Validator from 'validatorjs';

export default class RaptorOperatorLandingValidationService {
  constructor(private i18n: VueI18n) {}

  /**
   *
   * @param payload
   * @returns {Validator}
   */
  public externalDeviceRequest(payload): Validator {
    const rules = {
      manufacturingPlan: 'required|string|size:14',
      workCenter: 'required|string|min:1',
      orderNumber: 'required|string|size:15',
      operation: 'required:integer',
      operationSplit: 'required:integer',
      totalCompletedQty: 'required|integer|min:1',
      tagNumber: 'required|string|min:10',
    };
    const customAttributeNames = {
      orderNumber: this.i18n.t('raptorApp.customOperationTrack.orderNumber'),
      operation: this.i18n.t('raptorApp.customOperationTrack.operation'),
      operationSplit: this.i18n.t('raptorApp.customOperationTrack.operationSplit'),
      totalCompletedQty: this.i18n.t('raptorApp.customOperationTrack.totalCompletedQty'),
    };

    const validator = new Validator(payload, rules);
    validator.setAttributeNames(customAttributeNames);

    return validator;
  }

  /**
   *
   * @param payload
   * @returns {Validator}
   */
  public untagExternalDeviceRequest(payload): Validator {
    const rules = {
      manufacturingPlan: 'required|string|size:14',
      workCenter: 'required|string|min:1',
      orderNumber: 'required|string|size:15',
      operation: 'required:integer',
      operationSplit: 'required:integer',
      totalCompletedQty: 'required|integer|min:1',
    };
    const customAttributeNames = {
      orderNumber: this.i18n.t('raptorApp.customOperationTrack.orderNumber'),
      operation: this.i18n.t('raptorApp.customOperationTrack.operation'),
      operationSplit: this.i18n.t('raptorApp.customOperationTrack.operationSplit'),
      totalCompletedQty: this.i18n.t('raptorApp.customOperationTrack.totalCompletedQty'),
      tagNumber: this.i18n.t('raptorApp.linkDocumentToTag.tagNumber'),
    };

    const validator = new Validator(payload, rules);
    validator.setAttributeNames(customAttributeNames);

    return validator;
  }

  /**
   * @deprecated Using the linkDocumentToWorkOrderThenTrack() is preferable, backend is called once.
   */
  public linkDocumentToWorkOrder(payload): Validator {
    return this.linkDocumentToWorkOrderThenTrack(payload);
  }

  public linkDocumentToWorkOrderThenTrack(payload): Validator {
    const rules = {
      orderNumber: 'required|string|size:15',
      operation: 'required:integer',
      operationSplit: 'required:integer',
      totalCompletedQty: 'required|integer|min:1',
      tagNumber: 'required|string|min:10',
      'data.rowData': 'required',
    };
    const customAttributeNames = {
      orderNumber: this.i18n.t('raptorApp.customOperationTrack.orderNumber'),
      operation: this.i18n.t('raptorApp.customOperationTrack.operation'),
      operationSplit: this.i18n.t('raptorApp.customOperationTrack.operationSplit'),
      totalCompletedQty: this.i18n.t('raptorApp.customOperationTrack.totalCompletedQty'),
      tagNumber: this.i18n.t('raptorApp.linkDocumentToTag.tagNumber'),
    };

    const validator = new Validator(payload, rules);
    validator.setAttributeNames(customAttributeNames);

    return validator;
  }

  public complete(payload): Validator {
    const rules = {
      orderNumber: 'required|string|size:15',
      operation: 'required:integer',
      operationSplit: 'required:integer',
      totalCompletedQty: 'required|integer|min:1',
    };
    const customAttributeNames = {
      orderNumber: this.i18n.t('raptorApp.customOperationTrack.orderNumber'),
      operation: this.i18n.t('raptorApp.customOperationTrack.operation'),
      operationSplit: this.i18n.t('raptorApp.customOperationTrack.operationSplit'),
      totalCompletedQty: this.i18n.t('raptorApp.customOperationTrack.totalCompletedQty'),
    };

    const validator = new Validator(payload, rules);
    validator.setAttributeNames(customAttributeNames);

    return validator;
  }

  public step(name, payload) {
    switch (name) {
      case 'link-document-to-work-order':
      case 'link-document-to-work-order-then-track':
      case 'operation-track':
        return this.externalDeviceRequest(payload);
      case 'operation-track-without-context':
      case 'print-label':
        return this.untagExternalDeviceRequest(payload);
      case 'complete-without-operation-track':
        return this.complete(payload);
      default:
        throw Error(`Undefined validation rules for step ${name}.`);
    }
  }

  public static errorsMessage(validator): string {
    const errors = validator.errors.all();

    return Object.keys(errors).reduce((accumulator, currentValue) => {
      const currentValueReduced = errors[currentValue].reduce((a, c) => {
        return a + (a === '' ? '' : '<br>') + '&emsp;' + c;
      }, '');
      return accumulator + (accumulator === '' ? '' : '<br>') + currentValueReduced;
    }, '');
  }
}
