import axios from 'axios';

import buildPaginationQueryOpts from '@/shared/sort/sorts';

import buildCriteria from '@/shared/spe/filters';

const baseApiUrl = 'services/fargo/api/employee-trackers';
const baseApiUrlV1 = 'services/fargo/api/v1/employee-trackers';

export default class EmployeeTrackerExtendedService {
  public retrieve(criteria?: any, paginationQuery?: any): Promise<any> {
    const criteriaQuery = buildCriteria(criteria);
    const and = criteriaQuery.length > 0 && paginationQuery ? '&' : '';
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + `?${criteriaQuery}${and}${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
