import { Module } from 'vuex';
import * as type from '@/shared/config/store/raptor/mutation-types/bottom-sheet-store-types';
import { StatusType } from '@/shared/model/raptor/status-type.model';
import { YAKOVLEV_DEVICE_ID } from '@/shared/raptor/constants';
import ApplicationContext from '@/shared/raptor/context/application-context';

const yakovlevDevice = {
  id: YAKOVLEV_DEVICE_ID,
  icon: 'mdi-iobroker',
  type: StatusType.WebSocket,
  color: 'error',
  title: 'Yakovlev WebSocket',
  connected: false,
  countDownToReconnect: false,
  reconnect: false,
  reconnectInterval: 3000,
  message: null,
  error: null,
};

export interface BottomSheetStateStorable {
  sheet: boolean;
  items: { [k: string]: any }[];
}

export const defaultBottomSheetState: BottomSheetStateStorable = {
  sheet: false,
  items: [yakovlevDevice],
};

export const bottomSheetStore: Module<BottomSheetStateStorable, any> = {
  state: { ...defaultBottomSheetState },
  getters: {
    sheet: state => state.sheet,
    items: state => state.items,
  },
  mutations: {
    sheet(state, payload) {
      state.sheet = payload;
    },

    connected(state, payload) {
      state.items
        .filter(i => i.id === payload.id)
        .forEach(i => {
          i.color = 'success';
          i.reconnect = false;
          i.countDownToReconnect = false;
          i.message = ApplicationContext.getI18n().t('raptor.bottomSheet.connected');
        });
    },

    disconnected(state, payload) {
      state.items
        .filter(i => i.id === payload.id)
        .forEach(i => {
          i.color = 'error';

          if (i.type === StatusType.WebSocket) {
            i.reconnect = false;
            i.countDownToReconnect = true;
            i.message = ApplicationContext.getI18n().t('raptor.bottomSheet.disconnected');
          } else if (i.type === StatusType.Device) {
            i.reconnect = false;
            i.countDownToReconnect = false;
            i.message = payload.message;
          }
        });
    },

    reconnect(state, payload) {
      state.items
        .filter(i => i.id === payload.id)
        .forEach(i => {
          i.reconnect = true;
          i.countDownToReconnect = false;
          i.color = 'warning';
          i.message = ApplicationContext.getI18n().t('raptor.bottomSheet.connectingTo', { '0': i.title });
        });
    },

    set(state, payload) {
      state.items
        .filter(i => i.id === payload.id)
        .forEach(i => {
          if (payload.hasOwnProperty('color')) {
            i.color = payload.color;
          }
          if (payload.hasOwnProperty('message')) {
            i.message = payload.message;
          }
          if (payload.hasOwnProperty('error')) {
            i.error = payload.error;
          }
        });
    },

    reset(state, payload) {
      state.items = [yakovlevDevice];

      if (Array.isArray(payload)) {
        payload.forEach(it => state.items.push(it));
      } else {
        state.items.push(payload);
      }
    },
  },
  actions: {
    sheet({ commit }, payload) {
      commit(type.sheet, payload);
    },

    connected({ commit }, payload) {
      commit(type.connected, payload);
    },

    disconnected({ commit }, payload) {
      commit(type.disconnected, payload);
    },

    reconnect({ commit }, payload) {
      commit(type.reconnect, payload);
    },

    /**
     * Generic modifier, for a fine grained control
     * @param commit
     * @param payload
     */
    set({ commit }, payload) {
      commit(type.set, payload);
    },

    /**
     * Resets with the payload
     * @param commit
     * @param payload item|array
     * @returns {Promise<unknown>}
     */
    reset({ commit }, payload) {
      return new Promise(resolve => {
        commit(type.reset, payload);

        resolve(payload);
      });
    },

    remove({ commit }, payload) {
      commit(type.remove, payload);
    },
  },
  namespaced: true,
};
