/**
 * Given { state.in: [ 'RUNNING', 'PENDING' ]} return state.in=RUNNING&state.in=PENDING
 */
export default function buildCriteria(criteria: any) {
  if (!criteria) {
    return '';
  }
  let filters = '';
  for (const key in criteria) {
    if (criteria.hasOwnProperty(key)) {
      const value = criteria[key];
      if (Array.isArray(value)) {
        value.forEach(val => {
          if (filters.length > 0) {
            filters += '&';
          }
          filters += `${key}=${val}`;
        });
      } else {
        if (filters.length > 0) {
          filters += '&';
        }
        filters += `${key}=${value}`;
      }
    }
  }
  return filters;
}

export function zeroIfEmpty(arr: number[]): string {
  return arr.length === 0 ? '0' : arr.join(',');
}

export function nullIfEmpty(arr: number[]): string | null {
  return arr.length === 0 ? null : arr.join(',');
}

export function zeroArrayIfEmpty(arr: number[]): number[] {
  return arr.length === 0 ? [0] : arr;
}
