import { Module } from 'vuex';

export interface ProgressStateStorable {
  active: boolean;
  indeterminate: null | any;
  color: string;
}

export const defaultProgressState: ProgressStateStorable = {
  active: false,
  indeterminate: false,
  color: 'deep-purple accent-4',
};

export const progressStore: Module<ProgressStateStorable, any> = {
  state: { ...defaultProgressState },
  getters: {
    active: state => state.active,
    indeterminate: state => state.indeterminate,
    color: state => state.color,
  },
  mutations: {
    active(state, payload) {
      state.active = payload;
    },
    indeterminate(state, payload) {
      state.indeterminate = payload;
    },
  },
  namespaced: true,
};
