import * as MqttHeaders from '@/shared/raptor/messaging/support/mqtt-web-socket-headers';
import * as HttpHeaders from '@/shared/raptor/messaging/support/http-web-socket-headers';
import * as OpcUaHeaders from '@/shared/raptor/messaging/support/opcua-web-socket-headers';
import templateParser from '@/shared/raptor/messaging/support/template-parser';
import { MessageType } from '@/shared/raptor/messaging/support/message/message-type';
import * as Validator from 'validatorjs';
import ApplicationContext from '@/shared/raptor/context/application-context';
import { SUBSCRIBE_TOPIC, UNSUBSCRIBE_TOPIC } from '@/shared/raptor/messaging/support/topic-actions';
import { IPublishPayload, PublishPayload } from '@/shared/model/raptor/messaging/support/publish-payload.model';
import { OperatorLandingContext } from '@/shared/model/raptor/messaging/support/operator-landing-context.model';
import { AxiosResponse } from 'axios';
import { ComponentType } from '@/shared/model/enumerations/component-type.model';

/**
 * Return true if it's a mqtt message
 * @param genericMessage
 * @returns {boolean}
 */
function isMqttMessage(genericMessage: any): boolean {
  const headers = genericMessage.headers;

  return headers.hasOwnProperty(MqttHeaders.PUBLISH) || headers.hasOwnProperty(MqttHeaders.RECEIVED);
}

/**
 * Return true if it's a mqtt message and it comes from the mqtt broker
 * @param genericMessage
 * @returns {boolean}
 */
function isFromMqttBroker(genericMessage: any): boolean {
  return isMqttMessage(genericMessage);
}

/**
 * Return true if it's a Http message and it comes from the Http broker
 * @param genericMessage
 * @returns {boolean}
 */
function isFromHttpBroker(genericMessage: any): boolean {
  const headers = genericMessage.headers;
  return headers.hasOwnProperty(HttpHeaders.PUBLISH) || headers.hasOwnProperty(HttpHeaders.RECEIVED);
}

/**
 * Return true if it's a OpcUa message and it comes from the OpcUa broker
 * @param genericMessage
 * @returns {boolean}
 */
function isFromOpcUaBroker(genericMessage: any): boolean {
  const headers = genericMessage.headers;
  return headers.hasOwnProperty(OpcUaHeaders.PUBLISH) || headers.hasOwnProperty(OpcUaHeaders.RECEIVED);
}

/**
 * Return true if it's a recognized message
 * @param genericMessage
 */
function isFromKnownBroker(genericMessage: any): boolean {
  return isFromMqttBroker(genericMessage) || isFromHttpBroker(genericMessage) || isFromOpcUaBroker(genericMessage);
}

/**
 * Gets component type
 * @param genericMessage
 */
function getComponentType(genericMessage: any): ComponentType {
  if (isFromMqttBroker(genericMessage)) {
    return ComponentType.MQTT;
  } else if (isFromHttpBroker(genericMessage)) {
    return ComponentType.HTTP;
  } else if (isFromOpcUaBroker(genericMessage)) {
    return ComponentType.OPCUA;
  } else {
    throw new Error('Could not get ComponentType');
  }
}

/**
 * Gets mqtt type
 * @param genericMessage
 */
function getType(genericMessage: any) {
  const headers = genericMessage.headers;

  if (headers.hasOwnProperty(SUBSCRIBE_TOPIC)) {
    return SUBSCRIBE_TOPIC;
  } else if (headers.hasOwnProperty(UNSUBSCRIBE_TOPIC)) {
    return UNSUBSCRIBE_TOPIC;
  } else if (isFromMqttBroker(genericMessage)) {
    if (headers.hasOwnProperty(MqttHeaders.PUBLISH)) {
      return MqttHeaders.PUBLISH;
    } else if (headers.hasOwnProperty(MqttHeaders.RECEIVED)) {
      return MqttHeaders.RECEIVED;
    }
  } else if (isFromHttpBroker(genericMessage)) {
    if (headers.hasOwnProperty(HttpHeaders.PUBLISH)) {
      return HttpHeaders.PUBLISH;
    } else if (headers.hasOwnProperty(HttpHeaders.RECEIVED)) {
      return HttpHeaders.RECEIVED;
    }
  } else if (isFromOpcUaBroker(genericMessage)) {
    if (headers.hasOwnProperty(OpcUaHeaders.PUBLISH)) {
      return OpcUaHeaders.PUBLISH;
    } else if (headers.hasOwnProperty(OpcUaHeaders.RECEIVED)) {
      return OpcUaHeaders.RECEIVED;
    }
  }
  return null;
}

/**
 * Gets mqtt topic
 * @param genericMessage
 * @returns {*}
 */
function getTopic(genericMessage: any) {
  const headers = genericMessage.headers;

  if (headers.hasOwnProperty(SUBSCRIBE_TOPIC)) {
    return headers[SUBSCRIBE_TOPIC];
  } else if (headers.hasOwnProperty(UNSUBSCRIBE_TOPIC)) {
    return headers[UNSUBSCRIBE_TOPIC];
  } else if (isFromMqttBroker(genericMessage)) {
    if (headers.hasOwnProperty(MqttHeaders.PUBLISH)) {
      return headers[MqttHeaders.PUBLISH];
    } else if (headers.hasOwnProperty(MqttHeaders.RECEIVED)) {
      return headers[MqttHeaders.RECEIVED];
    }
  } else if (isFromHttpBroker(genericMessage)) {
    if (headers.hasOwnProperty(HttpHeaders.PUBLISH)) {
      return headers[HttpHeaders.PUBLISH];
    } else if (headers.hasOwnProperty(HttpHeaders.RECEIVED)) {
      return headers[HttpHeaders.RECEIVED];
    }
  } else if (isFromOpcUaBroker(genericMessage)) {
    if (headers.hasOwnProperty(OpcUaHeaders.PUBLISH)) {
      return headers[OpcUaHeaders.PUBLISH];
    } else if (headers.hasOwnProperty(OpcUaHeaders.RECEIVED)) {
      return headers[OpcUaHeaders.RECEIVED];
    }
  }
  return null;
}

/**
 * Build a new a mqtt message
 * @param device
 * @param stage
 * @param step
 * @param type request or response
 * @param payload
 * @returns {*}
 */
function build(device, stage, step, type, payload: IPublishPayload) {
  if (process.env.NODE_ENV !== 'production') {
    console.debug(
      `build : device=${device.code}, stage=${stage.name}, step=${step.name}, type=${type}, payload=${JSON.stringify(payload)}`
    );
  }
  const scope = {
    device,
    ...payload.getEvaluationContext(),
  };
  let componentType = null;
  if (payload?.context?.originalMessage) {
    componentType = getComponentType(payload?.context?.originalMessage);
  }
  if (!(type === MessageType.REQUEST || type === MessageType.RESPONSE)) {
    throw new Error(`Unknown type: ${type}. Valid types are: request and response.`);
  }
  let expression: string;
  try {
    expression = device.input;
    const topic = templateParser.fill(expression, scope);
    expression = step[type].message;
    const message = templateParser.fill(expression, scope);
    return { componentType, topic, message: JSON.parse(message) };
  } catch (err) {
    const i18n = ApplicationContext.getI18n();

    // Log error with untrimmed message
    console.error('****************************************************************************************************************');
    console.error('**************************************************** raptor ****************************************************');
    console.error(`Error building message for device '${device.code}' stage '${stage.name}' step '${step.name}`);
    console.error(`Error:`, err.message);
    console.error(`Expression:`, expression);
    console.error(`Scope:`, scope);
    console.error(`****************************************************************************************************************`);
    console.error(`****************************************************************************************************************`);

    const showLength = 60;
    const scopeString = JSON.stringify(scope);
    const trim = expression.length > showLength || scopeString.length;

    err.message = i18n.t('raptor.operatorLanding.errorBuildingMessage', {
      '0': device.code,
      '1': stage.name,
      '2': step.name,
      '3': err.message,
      '4': expression.length > showLength ? expression.slice(0, showLength) + '...' : expression,
      '5': scopeString.length > showLength ? scopeString.slice(0, showLength) + '...' : scopeString,
      '6': trim ? `<br>&nbsp;<br>${i18n.t('raptor.operatorLanding.checkConsoleForDetailedErrorMessages')}` : '',
    });
    throw new Error(err);
  }
}

/**
 * Prepare response for publish
 * Attention: Please note that when using MQTT the PLC can only accommodate payloads up to 1450 characters.
 * @param context
 * @param response
 */
function prepareResponse(context: OperatorLandingContext, response: AxiosResponse) {
  return new PublishPayload(context, response);
}

/**
 * Prepare error response for building mqtt message
 * @param messagePayload object with headers and payload properties
 * @param status
 * @param statusText
 * @returns {{response: {statusText, status, object: {data: string}}, message}}
 * @deprecated Use function prepareResponse
 */
function prepareErrorResponse(messagePayload, status, statusText) {
  return {
    message: messagePayload,
    response: {
      status: status,
      statusText: statusText,
      data: messagePayload.payload,
    },
  };
}

/**
 * @param filter
 * @param topic
 * @returns {boolean}
 *
 *  @see https://github.com/ralphtheninja/mqtt-match
 */
function match(filter, topic) {
  const match = require('mqtt-match');
  return match(filter, topic);
}

/**
 * Remove wildcards in the filter if they exist
 * @param filter
 * @returns {string}
 */
function removeEndWildcards(filter) {
  const filterArray = filter.split('/');
  const length = filterArray.length;
  const wildcards = ['#', '+'];

  let r = '';
  for (let i = length - 1; i >= 0; --i) {
    const right = filterArray[i];

    if (!wildcards.includes(right)) {
      const slice = filterArray.slice(0, i + 1);

      r = slice.reduce((previousValue, currentValue) => {
        return (previousValue !== '' ? previousValue + '/' : previousValue) + currentValue;
      }, '');
      break;
    }
  }
  return r;
}

/**
 * Gets the validator
 * <b>In</b> is not supported
 * @param message
 * @returns {Validator}
 */
function validator(message) {
  const i18n = ApplicationContext.getI18n();

  const rules = {
    headers: 'required',
    'headers.type': 'required|in:request,response',
    'headers.endpoint': 'required',
    'headers.uuid': 'required',
    payload: 'required',
  };
  const customAttributeNames = {
    headers: i18n.t('headers'),
    'headers.type': i18n.t('headerType'),
    'headers.endpoint': i18n.t('headerEndpoint'),
    'headers.uuid': i18n.t('headerUuid'),
    payload: i18n.t('payload'),
  };

  const validator = new Validator(message, rules);
  validator.setAttributeNames(customAttributeNames);

  return validator;
}

export default {
  isMqttMessage,
  isFromMqttBroker,
  isFromHttpBroker,
  isFromOpcUaBroker,
  isFromKnownBroker,
  getComponentType,
  getType,
  getTopic,
  match,
  build,
  prepareResponse,
  prepareErrorResponse,
  removeEndWildcards,
  validator,
};
