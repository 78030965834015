var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-menu",
    {
      attrs: { "offset-y": "", "min-width": "300px" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    { attrs: { icon: "", disabled: !_vm.authenticated } },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  !_vm.authenticated
                    ? _c("v-avatar", [_c("v-icon", [_vm._v("mdi-account")])], 1)
                    : _c(
                        "v-avatar",
                        { attrs: { color: "white", size: "32" } },
                        [
                          _c(
                            "span",
                            { staticClass: "black--text font-weight-medium" },
                            [_vm._v(_vm._s(_vm.initials))]
                          ),
                        ]
                      ),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [
      _vm._v(" "),
      _vm.authenticated
        ? _c(
            "div",
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-list",
                    { staticStyle: { "background-color": "#e8e8e8" } },
                    [
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "v-list-item-avatar",
                            [
                              _c("v-icon", { attrs: { large: "" } }, [
                                _vm._v("mdi-robot-industrial-outline"),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item-content",
                            [
                              _c(
                                "v-col",
                                { staticClass: "pa-0", attrs: { cols: "8" } },
                                [
                                  _c(
                                    "h3",
                                    {
                                      staticClass: "text-center",
                                      staticStyle: { "font-weight": "normal" },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.user.name) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-divider"),
                  _vm._v(" "),
                  _c(
                    "v-list",
                    [
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "v-list-item-content",
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-0",
                                      attrs: { cols: "4" },
                                    },
                                    [
                                      _c("h1", { staticClass: "text-center" }, [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(_vm.getDevices()) +
                                            "\n                "
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-0",
                                      attrs: { cols: "6" },
                                    },
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "raptor.operatorLanding.workCenterDevicesDialog.title"
                                            )
                                          ) + " "
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("v-list-item-subtitle", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "raptor.operatorLanding.workCenterDevicesDialog.subtitle"
                                            )
                                          ) + " "
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-icon", [_vm._v("mdi-devices")]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-divider"),
                  _vm._v(" "),
                  _c(
                    "v-list",
                    [
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "v-list-item-content",
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-0",
                                      attrs: { cols: "4" },
                                    },
                                    [
                                      _c("h1", { staticClass: "text-center" }, [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(_vm.getOperators()) +
                                            "\n                "
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-0",
                                      attrs: { cols: "6" },
                                    },
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "raptor.workCenterOperator.frequentOperators"
                                            )
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("v-list-item-subtitle", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "raptor.operatorLanding.workCenterDevicesDialog.subtitle"
                                            )
                                          ) + " "
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-icon", [_vm._v("mdi-account-hard-hat")]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.newOperators.length
                        ? [
                            _c(
                              "v-list-item",
                              [
                                _c(
                                  "v-list-item-content",
                                  [
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "pa-0",
                                            attrs: { cols: "4" },
                                          },
                                          [
                                            _c(
                                              "h1",
                                              { staticClass: "text-center" },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.getNewOperators()
                                                    ) +
                                                    "\n                  "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "pa-0",
                                            attrs: { cols: "6" },
                                          },
                                          [
                                            _c("v-list-item-title", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "raptor.workCenterOperator.noFrequentOperators"
                                                  )
                                                )
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("v-list-item-subtitle", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "raptor.operatorLanding.workCenterDevicesDialog.subtitle"
                                                  )
                                                ) + " "
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("v-icon", [_vm._v("mdi-account-hard-hat")]),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("v-divider"),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-list",
                    [
                      _c(
                        "v-list-item",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.logout()
                            },
                          },
                        },
                        [
                          _c(
                            "v-list-item-content",
                            { staticStyle: { "margin-left": "22px" } },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-card-actions",
                                    [
                                      _c("v-spacer"),
                                      _vm._v(" "),
                                      _c(
                                        "v-btn",
                                        {
                                          staticStyle: {
                                            "font-weight": "bold",
                                          },
                                          attrs: { color: "primary", text: "" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(_vm.$t("logout")) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-icon", [_vm._v("mdi-logout")]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c("div", [_c("p", [_vm._v("dkjsngfbngfg")])]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }