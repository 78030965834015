var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-app-bar",
    { attrs: { app: "", "clipped-right": "", dark: "" } },
    [
      _c(
        "v-app-bar-nav-icon",
        {
          attrs: { disabled: !_vm.authenticated },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.drawerBubbleUp.apply(null, arguments)
            },
          },
        },
        [_c("v-icon", [_vm._v("mdi-menu")])],
        1
      ),
      _vm._v(" "),
      _c("v-toolbar-title", {
        domProps: { textContent: _vm._s(_vm.$t("global.title")) },
      }),
      _vm._v(" "),
      _c("v-spacer"),
      _vm._v(" "),
      _c("go-apps"),
      _vm._v(" "),
      _c(
        "v-btn",
        { attrs: { icon: "", disabled: "" } },
        [_c("v-icon", [_vm._v("mdi-bell")])],
        1
      ),
      _vm._v(" "),
      _c("go-account", {
        attrs: { right: "" },
        on: { openLogin: _vm.openLogin, logout: _vm.logout },
      }),
      _vm._v(" "),
      _vm.languages && Object.keys(_vm.languages).length > 1
        ? _c("go-language", {
            attrs: { languages: _vm.languages },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function ({ languages }) {
                    return [
                      _c(
                        "v-list-item-group",
                        _vm._l(languages, function (value, key) {
                          return _c(
                            "v-list-item",
                            {
                              key: `lang-${key}`,
                              class: {
                                "v-list-item--active":
                                  _vm.isActiveLanguage(key),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.changeLanguage(key)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(value.name) +
                                  "\n        "
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              811064826
            ),
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-app-bar-nav-icon",
        {
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.drawerRightBubbleUp.apply(null, arguments)
            },
          },
        },
        [_c("v-icon", [_vm._v("mdi-dots-vertical")])],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }