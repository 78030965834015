// SPE Logger

export enum LogLevel {
  TRACE = 'trace',
  DEBUG = 'debug',
  INFO = 'info',
  WARN = 'warn',
  ERROR = 'error',
  FATAL = 'fatal',
}

export function appLogLevel() {
  return process.env.NODE_ENV !== 'production' ? LogLevel.DEBUG : LogLevel.INFO;
}
