import axios from 'axios';

import { ILinkDocumentToTagThenTrack } from '@/shared/model/raptor/link-document-to-tag-then-track.model';
import buildCriteria from '@/shared/spe/filters';
import buildPaginationQueryOpts from '@/shared/sort/sorts';
import { ILinkDocumentToTag } from '@/shared/model/link-document-to-tag.model';

const baseApiUrl = 'services/fargo/api/link-document-to-tags';
const baseApiUrlV1 = 'services/fargo/api/v1/link-document-to-tags';

export default class LinkDocumentToTagExtendedService {
  public retrieve(criteria?: any, paginationQuery?: any): Promise<any> {
    const criteriaQuery = buildCriteria(criteria);
    const and = criteriaQuery.length > 0 && paginationQuery ? '&' : '';
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + `?${criteriaQuery}${and}${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  /**
   * Attempts to add additional context when saving
   * and handles DataIntegrityViolationException by returning a 409 Conflict status if a link conflict occurs.
   */
  public create(entity: ILinkDocumentToTag): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`${baseApiUrlV1}`, entity)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public createThenTrack(entity: ILinkDocumentToTagThenTrack): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`${baseApiUrlV1}/then-tracks`, entity)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
