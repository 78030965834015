var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-app",
    { attrs: { id: "inspire" } },
    [
      _c(
        "v-navigation-drawer",
        {
          attrs: { app: "", clipped: "", right: "", width: "20%" },
          model: {
            value: _vm.drawerRight,
            callback: function ($$v) {
              _vm.drawerRight = $$v
            },
            expression: "drawerRight",
          },
        },
        [
          _c("go-right-drawer", {
            attrs: { right: _vm.right },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                _vm.right = !_vm.right
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("go-app-bar", {
        on: {
          drawerClick: function ($event) {
            $event.stopPropagation()
            _vm.drawer = !_vm.drawer
          },
          drawerRightClick: function ($event) {
            $event.stopPropagation()
            _vm.drawerRight = !_vm.drawerRight
          },
        },
      }),
      _vm._v(" "),
      _c(
        "v-navigation-drawer",
        {
          attrs: { app: "" },
          model: {
            value: _vm.drawer,
            callback: function ($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer",
          },
        },
        [
          _vm.authenticated
            ? _c("raptor-left-drawer", {
                attrs: { menu: "raptor", resolveLocalPrefix: "raptor" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    _vm.left = !_vm.left
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-main",
        { attrs: { fluid: "" } },
        [_c("go-alert-stack"), _vm._v(" "), _c("router-view")],
        1
      ),
      _vm._v(" "),
      _c(
        "v-navigation-drawer",
        {
          attrs: { fixed: "", right: "", temporary: "" },
          model: {
            value: _vm.right,
            callback: function ($$v) {
              _vm.right = $$v
            },
            expression: "right",
          },
        },
        [
          _c("go-right-drawer", {
            attrs: { right: _vm.right },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                _vm.right = !_vm.right
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("spe-bottom-navigation", { attrs: { active: true } }),
      _vm._v(" "),
      _c("raptor-bottom-sheet"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }