import { PUBLISH } from '@/shared/raptor/messaging/support/mqtt-web-socket-headers';
import * as type from '@/shared/config/store/raptor/mutation-types/yakovlev-store-types';
import { bottomSheetStoreMutationTypes, workCenterDeviceStoreMutationTypes } from '@/shared/config/store/raptor/mutation-types/types';
import { DeliveryStatus } from '@/shared/raptor/messaging/support/message/message-flow';
import { GenericMessage, MessageDirection } from '@/shared/model/raptor/messaging/support/message/generic-message.model';
import { is1xxInformational, is2xxSuccessful, isError } from '@/shared/raptor/messaging/support/message/message-status';
import { MessageType } from '@/shared/raptor/messaging/support/message/message-type';
import moment from 'moment';
import Vue from 'vue';
import yakovlevWebSocket from '@/shared/raptor/plugins/yakovlev-web-socket';
import { v4 as uuidv4 } from 'uuid';
import ApplicationContext from '@/shared/raptor/context/application-context';
import { Module } from 'vuex';
import { YAKOVLEV_DEVICE_ID } from '@/shared/raptor/constants';
import { SUBSCRIBE_TOPIC, UNSUBSCRIBE_TOPIC } from '@/shared/raptor/messaging/support/topic-actions';

export interface YakovlevStateStorable {
  connected: boolean;
  error: null | any;
  messages: any[];
  limit: number;
  getRowClass: any;
  columnDefs: any[];
  sideBar: { [k: string]: any };
  defaultColDef: { [k: string]: any };
  subscribedTopics: any;
}

const state: YakovlevStateStorable = {
  connected: false,
  error: null,

  messages: [],
  limit: 200,

  getRowClass: params => {
    return params.data.deliveryStatus === DeliveryStatus.FAILED ? 'red--text' : '';
  },

  /* # ---- ag-Grid --- # */
  columnDefs: [
    {
      field: 'direction',
      cellRenderer: data => {
        switch (data.value) {
          case MessageDirection.SENT:
            return '<i class="mdi mdi-arrow-up-thick red--text"><i>';
          case MessageDirection.RECEIVED:
            return '<i class="mdi mdi-arrow-down-thick green--text"><i>';
          default:
            return '<i class="mdi mdi-swap-vertical-bold"><i>';
        }
      },
      minWidth: 45,
      maxWidth: 45,
    },
    {
      field: 'responseStatus',
      headerName: 'Status',
      filter: 'agTextColumnFilter',
      cellRenderer: data => {
        if (is1xxInformational(data.value)) {
          return '<span class="info">' + data.value + '</span>';
        } else if (is2xxSuccessful(data.value)) {
          return '<span class="success">' + data.value + '</span>';
        } else if (isError(data.value)) {
          return '<span class="error">' + data.value + '</span>';
        } else {
          return '<span>' + data.value + '</span>';
        }
      },
      width: 105,
    },
    {
      field: 'type',
      filter: 'agTextColumnFilter',
      width: 170,
    },
    { field: 'topic', filter: 'agTextColumnFilter', width: 180 },
    {
      field: 'headers',
      hide: true,
    },
    { field: 'endpoint', filter: 'agTextColumnFilter', width: 215 },
    { field: 'uuid', filter: 'agTextColumnFilter', width: 295 },
    {
      field: 'timestamp',
      headerName: 'Time',
      filter: 'agDateColumnFilter',
      cellRenderer: data => {
        return moment(data.value).format('HH:mm:ss.SSS');
      },
      width: 124,
    },
    {
      field: 'deliveryStatus',
      hide: true,
    },
    {
      field: 'deliverySequence',
      hide: true,
    },
  ],
  // # ---- ag-Grid side bar
  sideBar: {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          suppressPivots: true,
          suppressPivotMode: true,
          suppressSideButtons: true,
          suppressColumnFilter: false,
          suppressColumnSelectAll: true,
          suppressColumnExpandAll: false,
        },
      },
      {
        id: 'filters',
        labelDefault: 'Filters',
        labelKey: 'filters',
        iconKey: 'filter',
        toolPanel: 'agFiltersToolPanel',
        // toolPanelParams: {
        //     suppressExpandAll: true,
        //     suppressFilterSearch: false,
        // }
      },
    ],
  },

  defaultColDef: { resizable: true },

  subscribedTopics: new Set(),
};

const getters = {
  displayMessages: state => state.messages,
  isConnected: state => state.connected,

  /**
   * ag-Grid
   */
  getRowClass: state => state.getRowClass,
  columnDefs: state => state.columnDefs,
  defaultColDef: state => state.defaultColDef,
  sideBar: state => state.sideBar,

  rowData: state => {
    return state.messages
      .slice(0)
      .reverse()
      .map(it => {
        const deserializeError = 'Deserialize error';

        if (it.headers !== null && it.payload !== null) {
          return {
            direction: it.direction,
            responseStatus: it.getResponseStatus() || '',
            type: it.getType() || null,
            topic: it.getTopic() || null,
            headers: JSON.stringify(it.headers) || null,
            endpoint: it.getPayloadHeaderEndpoint() || null,
            uuid: it.getPayloadHeaderUuid() || null,
            timestamp: it.headers.timestamp || null,
            payload: it.payload || null,
            deliveryStatus: it.getDeliveryStatus(),
            deliverySequence: it.getDeliverySequence(),
          };
        } else {
          return {
            direction: deserializeError,
            type: deserializeError,
            topic: deserializeError,
            headers: deserializeError,
            uuid: deserializeError,
            timestamp: deserializeError,
            payload: deserializeError,
            deliveryStatus: deserializeError,
            deliverySequence: deserializeError,
          };
        }
      });
  },

  subscribedTopics: state => state.subscribedTopics,
};

/**
 * Asynchronous process
 */
const actions = {
  addMessage({ commit, dispatch }, message) {
    if (message.getPayloadHeaderType() === MessageType.RESPONSE) {
      const uuid = message.getPayloadHeaderUuid();
      const deviceId = message.getDeviceCode();
      let status;

      if (uuid) {
        const responseStatus = message.getPayloadHeaderResponseStatus();
        const responseMessage = message.getPayloadHeaderResponseMessage();

        const payload = {
          uuid,
          response: {
            status: responseStatus,
            message: responseMessage,
          },
          deliveryStatus: DeliveryStatus.DELIVERED,
        };
        commit(type.updateMessageStatus, payload);

        if (isError(responseStatus)) {
          // Find the original request
          state.messages
            .filter(
              it =>
                it.getPayloadHeaderUuid() === uuid &&
                it.getPayloadHeaderType() === MessageType.REQUEST &&
                it.getPayloadHeaderType() === MessageType.REQUEST &&
                isError(it.getResponseStatus())
            )
            .forEach(it => {
              const mqttMessage = {
                topic: it.headers[PUBLISH],
                message: JSON.parse(it.payload),
              };

              // Generate a new UUID for the message
              mqttMessage.message.headers.uuid = uuidv4();

              dispatch(type.publishTopic, mqttMessage).then(r => {
                status = ApplicationContext.getI18n().t('errorReceivedForMessageRetryWithNewMessage', {
                  '0': responseStatus,
                  '1': uuid,
                  '2': r,
                });
                console.debug(status);

                // Open bottom sheet
                if (deviceId) {
                  dispatch(
                    bottomSheetStoreMutationTypes.set,
                    {
                      id: deviceId,
                      color: 'warning',
                      message: status,
                    },
                    { root: true }
                  ).then(() => {
                    console.debug(`Device ${deviceId} state set to warning.`);
                  });
                  dispatch(bottomSheetStoreMutationTypes.sheet, true, { root: true }).then(() => {
                    console.debug(`Bottom sheet opened.`);
                  });
                }
              });
            });
        }
      }
    }

    // Notify raptor
    dispatch(workCenterDeviceStoreMutationTypes.messageReceived, message, { root: true });
    commit(type.addMessage, message);
  },
  deleteMessage({ commit }, message) {
    commit(type.deleteMessage, message);
  },
  updateMessageStatus({ commit }, payload) {
    commit(type.updateMessageStatus, payload);
  },
  connectionOpened({ commit, dispatch, getters }) {
    commit(type.setConnection, true);

    dispatch(bottomSheetStoreMutationTypes.connected, { id: YAKOVLEV_DEVICE_ID }, { root: true });

    // Connection lost resubscribe channels
    const topics = getters['subscribedTopics'];
    topics.forEach((topic: string) => {
      if (process.env.NODE_ENV !== 'production') {
        console.debug(`Send subscribe message on topic '${topic}'`);
      }
      const message = GenericMessage.SubscribeMessage(topic);
      commit(type.subscribeTopic, message);
    });
  },

  /**
   * Called upon onclose event of WebSocket, performs cleanup tasks and notifies the application about the closure.
   */
  connectionClosed({ commit, dispatch }) {
    commit(type.setConnection, false);

    dispatch(bottomSheetStoreMutationTypes.sheet, true, { root: true });
    dispatch(bottomSheetStoreMutationTypes.disconnected, { id: YAKOVLEV_DEVICE_ID }, { root: true });
  },
  /**
   * Called upon onerror event of WebSocket
   */
  connectionError({ commit, dispatch }, error) {
    commit(type.setError, error);

    dispatch(bottomSheetStoreMutationTypes.set, { id: YAKOVLEV_DEVICE_ID, error }, { root: true });
  },
  subscribeTopic({ commit }, topic: string) {
    if (process.env.NODE_ENV !== 'production') {
      console.debug(`Subscribing topic '${topic}'`);
    }
    const message: GenericMessage = GenericMessage.SubscribeMessage(topic);
    commit(type.subscribeTopic, message);
    commit(type.addMessage, message);
  },
  unsubscribeTopic({ commit }, topic: string) {
    console.debug(`Unsubscribing topic '${topic}'`);
    const message: GenericMessage = GenericMessage.UnsubscribeMessage(topic);
    commit(type.unsubscribeTopic, message);
    commit(type.addMessage, message);
  },
  publishTopic({ commit }, data) {
    return new Promise(resolve => {
      const message: GenericMessage = GenericMessage.PublishMessage(data.componentType, data.topic, data.message);
      commit(type.publishTopic, message);
      commit(type.addMessage, message);

      resolve(message.getPayloadHeaderUuid());
    });
  },
  republishTopic({ commit }, data) {
    return new Promise(resolve => {
      const message: GenericMessage = GenericMessage.RepublishMessage(data.componentType, data.topic, data.message, data.flowControl);
      commit(type.publishTopic, message);
      commit(type.addMessage, message);

      resolve(message.getPayloadHeaderUuid());
    });
  },
  reconnect({ dispatch }) {
    dispatch(bottomSheetStoreMutationTypes.reconnect, { id: YAKOVLEV_DEVICE_ID }, { root: true });

    // Dynamically register a Vuex plugin?
    //
    // A Vuex plugin is simply a function that receives the store as the only argument, and is invoked in the
    // Store instance during construction.
    //
    // To apply a plugin after a Store has been constructed, you just need to invoke the plugin function and
    // pass the Store instance to that function:
    const plugin = yakovlevWebSocket.createPlugin(yakovlevWebSocket.createWebSocket());
    plugin(ApplicationContext.getStore());

    // Keep in mind that some plugins simply may not work correctly with already-constructed Store instances.
    // Your milage may vary.
  },
};

/**
 * Synchronous process
 */
const mutations = {
  addMessage(state, message) {
    while (state.messages.length >= state.limit) {
      state.messages.shift();
    }
    state.messages.push(message);
  },
  deleteMessage(state, message) {
    state.messages = state.messages.filter(m => m.id !== message.id);
  },

  /**
   * Updates message status, only message with DeliveryStatus.PENDING can be updated
   * @param state
   * @param payload an object containing the following two properties
   *  - uuid - the uuid of the message to be updated
   *  - deliverySequence - if specified only this message sequence will be updated
   *  - deliveryStatus - the new status
   */
  updateMessageStatus(state, payload) {
    state.messages.forEach((it, index) => {
      if (
        it.getPayloadHeaderUuid() === payload.uuid &&
        (payload.hasOwnProperty('deliverySequence') ? it.getDeliverySequence() === payload.deliverySequence : true) &&
        (payload.hasOwnProperty('deliveryStatus') ? it.getDeliveryStatus() === DeliveryStatus.PENDING : true)
      ) {
        if (payload.hasOwnProperty('deliveryStatus')) {
          it.flowControl.deliveryStatus = payload.deliveryStatus;
        }
        if (payload.hasOwnProperty('response')) {
          it.response = payload.response;
        }
        Vue.set(state.messages, index, it);
      }
    });
  },
  setConnection(state, message) {
    state.connected = message;
  },
  setError(state, error) {
    state.error = error;
  },
  subscribeTopic(state, message) {
    state.subscribedTopics.add(message.headers[SUBSCRIBE_TOPIC]);
  },
  unsubscribeTopic(state, message) {
    state.subscribedTopics.delete(message.headers[UNSUBSCRIBE_TOPIC]);
  },
  publishTopic(state, message) {},
};

export const yakovlevStore: Module<YakovlevStateStorable, any> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
