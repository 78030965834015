import axios from 'axios';
import { Module } from 'vuex';
import { IOperatorLandingLoading, OperatorLandingLoading } from '@/shared/config/store/raptor/model/operator-landing-loading.model';
import shopfloor from '@/shared/raptor/shopfloor';
import zmfgope from '@/shared/raptor/zmfgope';
import label from '@/shared/raptor/label';
import { progressStoreMutationTypes } from '@/shared/config/store/raptor/mutation-types/types';
import * as type from '@/shared/config/store/raptor/mutation-types/operator-landing-types';
import RaptorOperatorLandingService from '@/raptor/operator-landing/service/raptor-operator-landing.service';
import { AxiosSingleton } from '@/shared/config/axios-interceptor';
import QueryService from '@/shared/raptor/query/query.service';
import * as alertUtils from '@/shared/raptor/util/alert-utils';
import ApplicationContext from '@/shared/raptor/context/application-context';
import { ResponseStatusError } from '@/shared/raptor/messaging/support/error/response-status-error';
import { MessageStatus } from '@/shared/raptor/messaging/support/message/message-status';
import RaptorOperatorLandingValidationService from '@/raptor/operator-landing/service/raptor-operator-landing-validation.service';
import { EventBus } from '@gv/ammo-astra/dist';
import { FromOperatorLandingLine as customOperationTrackFromOperatorLandingLine } from '@/shared/model/raptor/operation-track-qualifying-fields.model';
import { FromOperatorLandingLine as skipOperationTrackFromOperatorLandingLine } from '@/shared/model/raptor/skip-operation-track-qualifying-fields.model';
import { ILinkDocumentToTag, LinkDocumentToTag } from '@/shared/model/link-document-to-tag.model';
import { ILinkDocumentToTagThenTrack, LinkDocumentToTagThenTrack } from '@/shared/model/raptor/link-document-to-tag-then-track.model';
import { ISkippedOperationTrack } from '@/shared/model/skipped-operation-track.model';
import { OperationIdentifier } from '@/shared/model/raptor/operation-identifier.model';
import LinkDocumentToTagService from '@/entities/link-document-to-tag/link-document-to-tag.service';
import LinkDocumentToTagExtendedService from '@/entities/link-document-to-tag/link-document-to-tag-extended.service';
import SkippedOperationTrackService from '@/entities/skipped-operation-track/skipped-operation-track.service';
import SkippedOperationTrackExtendedService from '@/entities/skipped-operation-track/skipped-operation-track-extended.service';
import CustomOperationTrackExtendedService from '@/entities/custom-operation-track/custom-operation-track-extended.service';
import CodingMarkingLabelService from '@/raptor/operator-landing/service/coding-marking-label.service';
import { appLogLevel, LogLevel } from '@/shared/config/logger-spe';
import { ICustomOperationTrack } from '@/shared/model/custom-operation-track.model';

const logLevel = appLogLevel();

const queryService = new QueryService(AxiosSingleton.getInstance());
const api = new RaptorOperatorLandingService(AxiosSingleton.getInstance(), queryService);
const linkDocumentToTagService = new LinkDocumentToTagService();
const linkDocumentToTagExtendedService = new LinkDocumentToTagExtendedService();
const customOperationTrackExtendedService = new CustomOperationTrackExtendedService();
const codingMarkingLabelService = new CodingMarkingLabelService();
const skippedOperationTrackService = new SkippedOperationTrackService();
const skippedOperationTrackExtendedService = new SkippedOperationTrackExtendedService();

/**
 * Handle errors that occur when attempting to link a tag to a work order document.
 * Specifically, this function addresses scenarios where a linkDocumentToTag conflict is detected (HTTP status 409).
 * When such conflicts occur, it retrieves existing link information to inform the user of the document’s current
 * association.
 */
const handleLinkDocumentToTagError = (error, payload, linkDocumentToTagExtendedService, reject) => {
  const i18n = ApplicationContext.getI18n();

  if (axios.isAxiosError(error) && error.response.status === 409) {
    const criteria = { 'tagNumber.equals': payload.tagNumber };

    return linkDocumentToTagExtendedService
      .retrieve(criteria)
      .then(existingLinkDocumentToTags => {
        if (existingLinkDocumentToTags.data.length === 1) {
          const existingLinkDocumentToTag = existingLinkDocumentToTags.data[0];
          const existingLinkAt = `${existingLinkDocumentToTag.document}~${existingLinkDocumentToTag.entryLine}~${existingLinkDocumentToTag.entrySequence}`;
          // @ts-ignore
          error.response.data.message += `<br>${i18n.t('raptor.operatorLanding.tagAlreadyLinked', { '0': existingLinkAt })}`;
        }
        reject(error);
      })
      .catch(() => {
        reject(error);
      });
  } else {
    reject(error);
  }
};

export interface OperatorLandingStateStorable {
  loading: IOperatorLandingLoading;
  rowData: any[];
  workCenter: null | string;
  manufacturingPlan: null | string;
  linkDocumentTags: ILinkDocumentToTag[];
}

export const defaultOperatorLandingState: OperatorLandingStateStorable = {
  loading: new OperatorLandingLoading(false, false, false, false, false, false, false),
  rowData: [],
  workCenter: null,
  manufacturingPlan: null,
  linkDocumentTags: [],
};

export const getters = {
  manufacturingPlan: state => {
    return state.manufacturingPlan;
  },

  rowData: state => {
    return state.rowData;
  },

  workCenter: state => {
    return state.workCenter;
  },

  current: state => {
    if (!state.rowData) {
      return null;
    }

    let i = -1;
    let j = -1;
    let found = false;
    let current = null;

    // Use vanilla js for better performance and the ability to break the loop
    for (i = 0; i < state.rowData.length; i++) {
      for (j = 0; j < state.rowData[i].children.length; j++) {
        const completed = state.rowData[i].children[j]['ZMFGOPETRK__ZAMTCPLQTY_0'];
        const skipped = state.rowData[i].children[j]['ZMFGOPETRK__ZAMTSKPCPLQTY_0'];
        const expected = state.rowData[i].children[j]['MFGITM__EXTQTY_0'];

        if (completed + skipped < expected) {
          found = true;
          break;
        }
      }

      // Child index found set parent index and break
      if (found) {
        current = {
          indexes: {
            parent: i,
            child: j,
          },
          rowData: state.rowData[i].children[j],
        };
        break;
      }
    }

    return current;
  },

  byOrderNumberAndOperationAndOperationSplit: state => payload => {
    const i18n = ApplicationContext.getI18n();
    const properties = [
      { key: 'orderNumber', validation: { required: true } },
      { key: 'operation', validation: { required: true, number: true } },
      { key: 'operationSplit', validation: { required: true, number: true } },
    ];

    properties.forEach(({ key, validation }) => {
      if (validation.required && !payload.hasOwnProperty(key)) {
        throw new Error(`${key}: ${i18n.t('entity.validation.required')}`);
      }
      if (validation.number && isNaN(payload[key])) {
        throw new Error(`${key}: ${i18n.t('entity.validation.number')}`);
      }
    });

    const orderNumber = payload.orderNumber;
    const operation = Math.trunc(payload.operation);
    const operationSplit = Math.trunc(payload.operationSplit);

    let i = -1;
    let j = -1;
    let found = false;
    let current = null;

    for (i = 0; i < state.rowData.length; i++) {
      for (j = 0; j < state.rowData[i].children.length; j++) {
        if (
          state.rowData[i].children[j]['MFGITM__MFGNUM_0'] === orderNumber &&
          state.rowData[i].children[j]['ZOPEWRKF__OPENUM_0'] === operation &&
          state.rowData[i].children[j]['ZOPEWRKF__OPESPLNUM_0'] === operationSplit
        ) {
          found = true;
          break;
        }
      }

      // Child index found set parent index and break
      if (found) {
        current = {
          indexes: {
            parent: i,
            child: j,
          },
          rowData: state.rowData[i].children[j],
        };
        break;
      }
    }

    return current;
  },

  lineProgress: (state, getters) => {
    const current = getters.current;

    if (!current) {
      return '0/0';
    }

    const i = current.indexes.parent;
    let j;
    let completed = 0;
    let skipped = 0;
    let expected = 0;

    for (j = 0; j < state.rowData[i].children.length; j++) {
      completed += state.rowData[i].children[j]['ZMFGOPETRK__ZAMTCPLQTY_0'];
      skipped += state.rowData[i].children[j]['ZMFGOPETRK__ZAMTSKPCPLQTY_0'];
      expected += state.rowData[i].children[j]['MFGITM__EXTQTY_0'];
    }

    return `${skipped + completed}/${expected}`;
  },

  totalProgress: state => {
    if (!state.rowData || state.rowData.length === 0) {
      return '0/0';
    }

    let i;
    let j;
    let completed = 0;
    let skipped = 0;
    let expected = 0;

    for (i = 0; i < state.rowData.length; i++) {
      for (j = 0; j < state.rowData[i].children.length; j++) {
        completed += state.rowData[i].children[j]['ZMFGOPETRK__ZAMTCPLQTY_0'];
        skipped += state.rowData[i].children[j]['ZMFGOPETRK__ZAMTSKPCPLQTY_0'];
        expected += state.rowData[i].children[j]['MFGITM__EXTQTY_0'];
      }
    }

    return `${skipped + completed}/${expected}`;
  },

  willOverTrack: state => payload => {
    const rowData = state.rowData[payload.data.indexes.parent].children[payload.data.indexes.child];
    const completed = rowData['ZMFGOPETRK__ZAMTCPLQTY_0'];
    const skipped = rowData['ZMFGOPETRK__ZAMTSKPCPLQTY_0'];
    const expected = rowData['MFGITM__EXTQTY_0'];

    return completed + skipped + payload.totalCompletedQty > expected;
  },

  loading: state => {
    return state.loading;
  },

  linkDocumentTags: state => {
    return state.linkDocumentTags;
  },
};
/**
 * Asynchronous process
 */
const actions = {
  manufacturingPlan(context, payload) {
    context.commit(type.manufacturingPlan, payload.manufacturingPlan);
  },

  fetchData(context, payload) {
    const i18n = ApplicationContext.getI18n();

    const manufacturingSite = payload.manufacturingSite;
    const workCenter = payload.workCenter;
    const manufacturingPlan = payload.manufacturingPlan;

    let productionResourceLoadData = null;
    let productionResourceData = null;

    context.commit(type.loading, { key: 'main', value: true });
    context.commit(progressStoreMutationTypes.indeterminate, true, { root: true });
    context.commit(progressStoreMutationTypes.active, true, { root: true });

    const productionResourcePromise = api
      .getProductionResource(workCenter, manufacturingPlan)
      .then(response => {
        productionResourceData = response.data[0];
      })
      .catch(error => {
        alertUtils.showError(error);
      });

    const productionResourceLoadPromise = api
      .getProductionResourceLoad(manufacturingSite, workCenter, manufacturingPlan)
      .then(response => {
        productionResourceLoadData = response.data;
      })
      .catch(error => {
        alertUtils.showError(error);
      });

    Promise.all([productionResourcePromise, productionResourceLoadPromise])
      .then(() => {
        context.commit(type.workCenter, productionResourceData);

        context.commit(
          type.rowData,
          shopfloor.orderBy(
            shopfloor.groupBy(productionResourceLoadData, zmfgope.buildGroupKey),
            zmfgope.buildGroups,
            zmfgope.buildGroupsOrderBy(productionResourceData)
          )
        );

        context.commit(type.loading, { key: 'main', value: false });
        context.commit(progressStoreMutationTypes.active, false, { root: true });

        if (!productionResourceLoadData.length) {
          alertUtils.showError(
            i18n.t('raptor.operatorLanding.noDataIsAvailableFor', {
              '0': manufacturingPlan,
            })
          );
        }
      })
      .catch(error => {
        context.commit(type.loading, { key: 'main', value: false });
        context.commit(progressStoreMutationTypes.active, false, { root: true });
      });
  },

  /**
   * Associate a tag to and work order
   * @param context
   * @param payload Object should has two properties current and ZTAGNUM_0
   * @returns {Promise<unknown>}
   */
  linkDocumentToWorkOrder(context, payload) {
    const i18n = ApplicationContext.getI18n();
    const validationService = new RaptorOperatorLandingValidationService(i18n);

    return new Promise((resolve, reject) => {
      const method = i18n.t('raptor.operatorLanding.pairTagShort');

      const validator = validationService.linkDocumentToWorkOrder(payload);
      if (validator.fails()) {
        const errorMessage = RaptorOperatorLandingValidationService.errorsMessage(validator);
        alertUtils.showError(`${method}:<br>&nbsp;&nbsp;&nbsp;&nbsp;${errorMessage}`);

        throw new ResponseStatusError(errorMessage, MessageStatus.BAD_REQUEST);
      }

      if (context.getters[type.willOverTrack](payload)) {
        const errorMessage = i18n.t('raptor.operatorLanding.requestFailedDueToOverTrack');
        alertUtils.showError(`${method}:<br>&nbsp;&nbsp;&nbsp;&nbsp;${errorMessage}`);

        throw new ResponseStatusError(errorMessage, MessageStatus.BAD_REQUEST);
      }

      context.commit(type.loading, { key: 'linkDocumentToWorkOrder', value: true });

      const linkDocumentToTag: ILinkDocumentToTag = new LinkDocumentToTag(
        null,
        0,
        10,
        payload.orderNumber,
        payload.operation,
        payload.operationSplit,
        payload.tagNumber,
        'device',
        '0001',
        JSON.stringify({ ZLNKDOCTAG__ZTAGNUM_0: payload.tagNumber, ...payload.data.rowData })
      );

      linkDocumentToTagExtendedService
        .create(linkDocumentToTag)
        .then(res => {
          context.commit(type.loading, { key: 'linkDocumentToWorkOrder', value: false });
          context.dispatch('retrieveLinkDocumentTags').then(() => {});
          resolve(res);
        })
        .catch(err => {
          context.commit(type.loading, { key: 'linkDocumentToWorkOrder', value: false });
          handleLinkDocumentToTagError(err, payload, linkDocumentToTagExtendedService, reject);
        });
    });
  },

  /**
   * Associate a tag to and work order, then creat an operation track for the associated work center
   * @param context
   * @param payload Object should has two properties current and ZTAGNUM_0
   * @returns {Promise<unknown>}
   */
  linkDocumentToWorkOrderThenTrack(context, payload): Promise<any> {
    const i18n = ApplicationContext.getI18n();

    const validationService = new RaptorOperatorLandingValidationService(i18n);

    return new Promise((resolve, reject) => {
      const method = i18n.t('raptor.operatorLanding.pairTagShort');
      const validator = validationService.linkDocumentToWorkOrder(payload);
      if (validator.fails()) {
        const errorMessage = RaptorOperatorLandingValidationService.errorsMessage(validator);
        alertUtils.showError(`${method}:<br>&nbsp;&nbsp;&nbsp;&nbsp;${errorMessage}`);

        reject(new ResponseStatusError(errorMessage, MessageStatus.BAD_REQUEST));
      }

      if (context.getters[type.willOverTrack](payload)) {
        const errorMessage = i18n.t('raptor.operatorLanding.requestFailedDueToOverTrack');
        alertUtils.showError(`${method}:<br>&nbsp;&nbsp;&nbsp;&nbsp;${errorMessage}`);

        reject(new ResponseStatusError(errorMessage, MessageStatus.BAD_REQUEST));
      }

      const linkDocumentToTag: ILinkDocumentToTag = new LinkDocumentToTag(
        null,
        0,
        10,
        payload.orderNumber,
        payload.operation,
        payload.operationSplit,
        payload.tagNumber,
        'device',
        '0001',
        JSON.stringify({ ZLNKDOCTAG__ZTAGNUM_0: payload.tagNumber, ...payload.data.rowData })
      );
      const customOperationTrack: ICustomOperationTrack = customOperationTrackFromOperatorLandingLine(
        payload.data.rowData,
        payload.totalCompletedQty,
        payload.tagNumber
      );

      const linkDocumentToTagThenTrack: ILinkDocumentToTagThenTrack = new LinkDocumentToTagThenTrack(
        linkDocumentToTag,
        customOperationTrack
      );

      context.commit(type.loading, { key: 'linkDocumentToWorkOrder', value: true });
      linkDocumentToTagExtendedService
        .createThenTrack(linkDocumentToTagThenTrack)
        .then(res => {
          context.commit(type.loading, { key: 'linkDocumentToWorkOrder', value: false });
          context.dispatch('retrieveLinkDocumentTags').then(() => {});
          context.commit(type.updateCompletedQuantity, {
            parentIndex: payload.data.indexes.parent,
            childIndex: payload.data.indexes.child,
            completedQuantity: payload.totalCompletedQty,
          });

          // Invoke gridApi.refreshClientSideRowModel('filter') in ag-Grid
          EventBus.getInstance().dispatch('refreshClientSideRowModel', { filter: true });
          resolve(res);
        })
        .catch(err => {
          context.commit(type.loading, { key: 'linkDocumentToWorkOrder', value: false });
          handleLinkDocumentToTagError(err, payload, linkDocumentToTagExtendedService, reject);
        });
    });
  },

  printLabel(context, payload) {
    const i18n = ApplicationContext.getI18n();

    return new Promise((resolve, reject) => {
      if (!payload.data) {
        const message = i18n.t('raptor.operatorLanding.undefinedOrNull', { '0': i18n.t('data') }).toString();
        reject(new Error(message));
        return;
      }
      context.commit(type.loading, { key: 'printLabel', value: true });
      codingMarkingLabelService
        .buildAndSendFileToPrinter(payload.data.rowData)
        .then(response => {
          context.commit(type.loading, { key: 'printLabel', value: false });

          alertUtils.showSuccess(
            `${i18n.t('raptor.operatorLanding.successfullySentToThePrinter', {
              '0': label.product(payload.data.rowData),
            })}`
          );
          resolve(response);
        })
        .catch(error => {
          context.commit(type.loading, { key: 'printLabel', value: false });
          reject(error);
        });
    });
  },

  /**
   * Complete
   * @param context
   * @param payload Object should has two properties current and CPLQTY_0
   * @returns {Promise<unknown>}
   */
  complete(context, payload) {
    logLevel === LogLevel.DEBUG && console.debug('complete : context=', context, ', payload=', payload);
    const i18n = ApplicationContext.getI18n();
    const validationService = new RaptorOperatorLandingValidationService(i18n);

    return new Promise((resolve, reject) => {
      const method = i18n.t('raptor.operatorLanding.complete');

      const validator = validationService.complete(payload);
      if (validator.fails()) {
        throw new Error(`${method}:<br>${RaptorOperatorLandingValidationService.errorsMessage(validator)}`);
      }
      context.commit(type.loading, { key: 'complete', value: true });
      const customOperationTrack = customOperationTrackFromOperatorLandingLine(
        payload.data.rowData,
        payload.totalCompletedQty,
        payload.tagNumber
      );
      customOperationTrackExtendedService
        .create(customOperationTrack)
        .then(response => {
          context.commit(type.updateCompletedQuantity, {
            parentIndex: payload.data.indexes.parent,
            childIndex: payload.data.indexes.child,
            completedQuantity: payload.totalCompletedQty,
          });
          context.commit(type.loading, { key: 'complete', value: false });

          // Invoke gridApi.refreshClientSideRowModel('filter') in ag-Grid
          EventBus.getInstance().dispatch('refreshClientSideRowModel', { filter: true });

          alertUtils.showSuccess(
            `${i18n.t('raptor.operatorLanding.successfullyCompletedOperation', {
              '0': payload.data.rowData['MFGITM__MFGNUM_0'],
              '1': payload.data.rowData['ZOPEWRKF__OPENUM_0'],
              '2': payload.data.rowData['ZOPEWRKF__OPESPLNUM_0'],
            })}`
          );
          resolve(response);
        })
        .catch(error => {
          context.commit(type.loading, { key: 'complete', value: false });
          alertUtils.showError(error);
          reject(error);
        });
    });
  },

  /**
   * Complete without operation track
   * @param context
   * @param payload
   */
  completeWithoutOperationTrack(context, payload) {
    logLevel === LogLevel.DEBUG && console.debug('completeWithoutOperationTrack : context=', context, ', payload=', payload);
    const i18n = ApplicationContext.getI18n();
    const validationService = new RaptorOperatorLandingValidationService(i18n);

    return new Promise((resolve, reject) => {
      const method = i18n.t('raptor.operatorLanding.complete');

      const validator = validationService.complete(payload);
      if (validator.fails()) {
        throw new Error(`${method}:<br>${RaptorOperatorLandingValidationService.errorsMessage(validator)}`);
      }
      context.commit(type.updateCompletedQuantity, {
        parentIndex: payload.data.indexes.parent,
        childIndex: payload.data.indexes.child,
        completedQuantity: payload.totalCompletedQty,
      });
      context.commit(type.loading, { key: 'complete', value: false });

      // Invoke gridApi.refreshClientSideRowModel('filter') in ag-Grid
      EventBus.getInstance().dispatch('refreshClientSideRowModel', { filter: true });

      alertUtils.showSuccess(
        `${i18n.t('raptor.operatorLanding.successfullyCompletedOperation', {
          '0': payload.data.rowData['MFGITM__MFGNUM_0'],
          '1': payload.data.rowData['ZOPEWRKF__OPENUM_0'],
          '2': payload.data.rowData['ZOPEWRKF__OPESPLNUM_0'],
        })}`
      );
    });
  },

  skip(context, payload) {
    const i18n = ApplicationContext.getI18n();

    return new Promise((resolve, reject) => {
      context.commit(type.loading, { key: 'skip', value: true });
      const skipOperationTrack: ISkippedOperationTrack = skipOperationTrackFromOperatorLandingLine(
        payload.data.rowData,
        payload.totalCompletedQty
      );
      skippedOperationTrackService
        .create(skipOperationTrack)
        .then(response => {
          context.commit(type.skip, {
            parentIndex: payload.data.indexes.parent,
            childIndex: payload.data.indexes.child,
            completedQuantity: payload.completedQuantity,
          });
          context.commit(type.loading, { key: 'skip', value: false });

          // Invoke gridApi.refreshClientSideRowModel('filter') in ag-Grid
          EventBus.getInstance().dispatch('refreshClientSideRowModel', { filter: true });

          alertUtils.showSuccess(
            `${i18n.t('raptor.operatorLanding.successfullySkippedOperation', {
              '0': payload.data.rowData['MFGITM__MFGNUM_0'],
              '1': payload.data.rowData['ZOPEWRKF__OPENUM_0'],
              '2': payload.data.rowData['ZOPEWRKF__OPESPLNUM_0'],
            })}`
          );
          resolve(response);
        })
        .catch(error => {
          context.commit(type.loading, { key: 'skip', value: false });
          alertUtils.showError(error);
          reject(error);
        });
    });
  },

  //
  // Actions on multiple lines
  //

  /**
   * Complete operation(s) for the selected lines
   *
   * @param context
   * @param payload
   * @returns {Promise<unknown>}
   */
  completeAll(context, payload) {
    const i18n = ApplicationContext.getI18n();

    return new Promise((resolve, reject) => {
      const list = payload.list;

      context.commit(type.loading, { key: 'complete', value: true });
      customOperationTrackExtendedService
        .createBulk(
          list.map(item => {
            return customOperationTrackFromOperatorLandingLine(item.data, item['CPLQTY_0'], null);
          })
        )
        .then(() => {
          context.commit(type.completeAll, { list });
          context.commit(type.loading, { key: 'complete', value: false });

          if (list.length === 1) {
            alertUtils.showSuccess(
              `${i18n.t('raptor.operatorLanding.successfullyCompletedOperation', {
                '0': list[0].data['MFGITM__MFGNUM_0'],
                '1': list[0].data['ZOPEWRKF__OPENUM_0'],
                '2': list[0].data['ZOPEWRKF__OPESPLNUM_0'],
              })}`
            );
          } else {
            alertUtils.showSuccess(`${i18n.t('raptor.operatorLanding.successfullyCompletedMultipleOperation')}`);
          }
          resolve(list);
        })
        .catch(error => {
          context.commit(type.loading, { key: 'complete', value: false });
          alertUtils.showError(error);
          reject(error);
        });
    });
  },

  /**
   * Skip operation(s) for the selected lines
   *
   * @param context
   * @param payload
   * @returns {Promise<unknown>}
   */
  skipAll(context, payload) {
    const i18n = ApplicationContext.getI18n();

    return new Promise((resolve, reject) => {
      const list = payload.list;

      context.commit(type.loading, { key: 'skip', value: true });
      skippedOperationTrackExtendedService
        .createBulk(
          list.map(item => {
            return skipOperationTrackFromOperatorLandingLine(item.data, item['CPLQTY_0']);
          })
        )
        .then(() => {
          context.commit(type.skipAll, { list });
          context.commit(type.loading, { key: 'skip', value: false });

          if (list.length === 1) {
            alertUtils.showSuccess(
              `${i18n.t('raptor.operatorLanding.successfullySkippedOperation', {
                '0': list[0].data['MFGITM__MFGNUM_0'],
                '1': list[0].data['ZOPEWRKF__OPENUM_0'],
                '2': list[0].data['ZOPEWRKF__OPESPLNUM_0'],
              })}`
            );
          } else {
            alertUtils.showSuccess(`${i18n.t('raptor.operatorLanding.successfullySkippedMultipleOperation')}`);
          }
          resolve(list);
        })
        .catch(error => {
          context.commit(type.loading, { key: 'skip', value: false });
          alertUtils.showError(error);
          reject(error);
        });
    });
  },

  /**
   * Reset operation(s) for the selected lines
   */
  resetAll(context, payload) {
    const i18n = ApplicationContext.getI18n();

    return new Promise((resolve, reject) => {
      const list = payload.list;

      context.commit(type.loading, { key: 'complete', value: true });
      customOperationTrackExtendedService
        .deleteAllByOperationIdentifier(
          list.map(
            item =>
              new OperationIdentifier(item.data['MFGITM__MFGNUM_0'], item.data['ZOPEWRKF__OPENUM_0'], item.data['ZOPEWRKF__OPESPLNUM_0'])
          )
        )
        .then(response => {
          context.commit(type.resetAll, { list });
          context.commit(type.loading, { key: 'complete', value: false });

          if (list.length === 1) {
            alertUtils.showSuccess(
              `${i18n.t('raptor.operatorLanding.successfullyResetOperation', {
                '0': list[0].data['MFGITM__MFGNUM_0'],
                '1': list[0].data['ZOPEWRKF__OPENUM_0'],
                '2': list[0].data['ZOPEWRKF__OPESPLNUM_0'],
              })}`
            );
          } else {
            alertUtils.showSuccess(`${i18n.t('raptor.operatorLanding.successfullyResetMultipleOperation')}`);
          }

          resolve(list);
        })
        .catch(error => {
          context.commit(type.loading, { key: 'complete', value: false });

          alertUtils.showError(error);
          reject(error);
        });
    });
  },

  /**
   * Resume operation(s) for the selected lines
   * @param context
   * @param payload
   * @returns {Promise<unknown>}
   */
  resumeAll(context, payload) {
    const i18n = ApplicationContext.getI18n();

    return new Promise((resolve, reject) => {
      const list = payload.list;

      context.commit(type.loading, { key: 'skip', value: true });
      skippedOperationTrackExtendedService
        .deleteAllByOperationIdentifier(
          list.map(
            item =>
              new OperationIdentifier(item.data['MFGITM__MFGNUM_0'], item.data['ZOPEWRKF__OPENUM_0'], item.data['ZOPEWRKF__OPESPLNUM_0'])
          )
        )
        .then(() => {
          context.commit(type.resumeAll, { list });
          context.commit(type.loading, { key: 'skip', value: false });

          if (list.length === 1) {
            alertUtils.showSuccess(
              `${i18n.t('raptor.operatorLanding.successfullyResumeOperation', {
                '0': list[0].data['MFGITM__MFGNUM_0'],
                '1': list[0].data['ZOPEWRKF__OPENUM_0'],
                '2': list[0].data['ZOPEWRKF__OPESPLNUM_0'],
              })}`
            );
          } else {
            alertUtils.showSuccess(`${i18n.t('raptor.operatorLanding.successfullyResumeMultipleOperation')}`);
          }
          resolve(list);
        })
        .catch(error => {
          context.commit(type.loading, { key: 'skip', value: false });
          alertUtils.showError(error);
          reject(error);
        });
    });
  },

  retrieveLinkDocumentTags(context) {
    const paginationQuery = {
      page: 0,
      size: 10,
      sort: ['id,desc'],
    };

    linkDocumentToTagService
      .retrieve(paginationQuery)
      .then(res => {
        context.commit(type.setLinkDocumentTags, res.data);
      })
      .catch(err => {
        alertUtils.showError(err);
      });
  },
};

/**
 * Synchronous process
 */
const mutations = {
  manufacturingPlan(state, payload) {
    state.manufacturingPlan = payload.manufacturingPlan;
  },

  rowData(state, payload) {
    state.rowData = payload;
  },

  workCenter(state, payload) {
    state.workCenter = payload;
  },

  updateCompletedQuantity(state, payload) {
    state.rowData[payload.parentIndex].children[payload.childIndex]['ZMFGOPETRK__ZAMTCPLQTY_0'] += payload.completedQuantity;
  },

  skip(state, payload) {
    const item = state.rowData[payload.parentIndex].children[payload.childIndex];
    item['ZMFGOPETRK__ZAMTSKPCPLQTY_0'] += payload.completedQuantity;
  },

  completeAll(state, payload) {
    payload.list.forEach(item => {
      item.data['ZMFGOPETRK__ZAMTCPLQTY_0'] += item['CPLQTY_0'];
    });
  },

  skipAll(state, payload) {
    payload.list.forEach(item => {
      item.data['ZMFGOPETRK__ZAMTSKPCPLQTY_0'] += item['CPLQTY_0'];
    });
  },

  resetAll(state, payload) {
    payload.list.forEach(item => (item.data['ZMFGOPETRK__ZAMTCPLQTY_0'] = item['CPLQTY_0']));
  },

  resumeAll(state, payload) {
    payload.list.forEach(item => (item.data['ZMFGOPETRK__ZAMTSKPCPLQTY_0'] = item['CPLQTY_0']));
  },

  loading(state, payload) {
    state.loading[payload.key] = payload.value;
  },

  setLinkDocumentTags(state, linkDocumentTags) {
    state.linkDocumentTags = linkDocumentTags;
  },
};

export const operatorLandingStore: Module<OperatorLandingStateStorable, any> = {
  state: { ...defaultOperatorLandingState },
  getters,
  actions,
  mutations,
  namespaced: true,
};
