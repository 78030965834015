import kioskComponent from '@/core/spe/kiosk/kiosk.vue';
import { Authority } from '@/shared/security/authority';

export default [
  {
    path: '/kiosk',
    name: 'kiosk',
    component: kioskComponent,
    meta: {},
  },
];
