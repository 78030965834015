import axios from 'axios';

import ApplicationContext from '@/shared/raptor/context/application-context';

const baseApiUrl = 'services/fargo/api/v1/coding-marking-labels';

const ENVIRONMENT_HTTP_HEADER = 'X-Environment';
const PRINTER_HTTP_HEADER = 'X-Printer';

export default class CodingMarkingLabelService {
  public buildAndSendFileToPrinter(payload: { [k: string]: any }): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const devices = ApplicationContext.getStore().getters['workCenterDeviceStore/devices'];
      const device = devices.find(device => device.destination?.trim() !== '');

      if (!device) {
        const i18n = ApplicationContext.getI18n();
        const codes = devices.map(device => device.code).join(', ');
        reject(new Error(i18n.t('raptor.operatorLanding.errorDestinationNotFoundInDevices', { '0': codes }).toString()));
      }

      const headers = {
        [ENVIRONMENT_HTTP_HEADER]: SHOP_FLOOR_PRINTER_MODE,
        [PRINTER_HTTP_HEADER]: device.destination,
      };
      axios
        .post(`${baseApiUrl}/build-and-send-file-to-printer`, payload, { headers })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
