import { Module } from 'vuex';

export interface WorkCenterAccountStateStorable {
  status: string;
  token: string;
  user: { [k: string]: any };
}

export const defaultWorkCenterAccountState: WorkCenterAccountStateStorable = {
  status: '',
  token: localStorage.getItem('token') || '',
  user: {},
};

export const workCenterAccountStore: Module<WorkCenterAccountStateStorable, any> = {
  state: { ...defaultWorkCenterAccountState },
  getters: {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    user: state => state.user,
  },
  mutations: {
    authRequest(state) {
      state.status = 'loading';
    },
    authSuccess(state, payload) {
      state.status = 'success';
      state.token = payload.token;
      state.user = payload.user;
    },
    authError(state) {
      state.status = 'error';
    },
    logout(state) {
      (state.status = ''), (state.token = ''), (state.user = {});
    },
  },
  namespaced: true,
};
