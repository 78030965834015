import Vue from 'vue';
import Component from 'vue-class-component';
import Ribbon from '@/core/ribbon/ribbon.vue';
// import JhiFooter from '@/core/jhi-footer/jhi-footer.vue'; // SPE
// import JhiNavbar from '@/core/jhi-navbar/jhi-navbar.vue';  // SPE
// import LoginForm from '@/account/login-form/login-form.vue'; // SPE

import '@/shared/config/dayjs';

// SPE Begin custom imports
import { EventBus } from '@gv/ammo-astra/dist';
import { EventBusType } from '@gv/ammo-astra/dist';
import GoAppBar from '@/core/raptor/go-app-bar/go-app-bar.vue';
// import { GoRightComponent as GoRight } from '@gv/ammo-grome';
import { GoRightDrawerComponent as GoRightDrawer } from '@gv/ammo-grome';
import { GoAlertStackComponent as GoAlertStack } from '@gv/ammo-grome';
import LeftDrawer from '@/core/left-drawer/left-drawer.vue';
import SpeBottomNavigation from '@/core/raptor/spe-bottom-navigation/spe-bottom-navigation.vue';
import RaptorBottomSheet from '@/raptor/bottom-sheet/raptor-bottom-sheet.vue';
import { Inject, Watch } from 'vue-property-decorator';
import AccountService from '@/account/work-center-authentication/account.service';
// SPE End custom imports

@Component({
  components: {
    ribbon: Ribbon,
    // 'jhi-navbar': JhiNavbar, // SPE
    // 'login-form': LoginForm, // SPE

    // 'jhi-footer': JhiFooter, // SPE
    // SPE begin
    'go-app-bar': GoAppBar,
    'raptor-left-drawer': LeftDrawer,
    'go-right-drawer': GoRightDrawer,
    'go-alert-stack': GoAlertStack,
    'spe-bottom-navigation': SpeBottomNavigation,
    'raptor-bottom-sheet': RaptorBottomSheet,
    // SPE end
  },
})
export default class App extends Vue {
  // SPE Begin

  @Inject('accountService')
  private accountService!: () => AccountService;

  private drawer = false;
  private drawerRight = false;
  private right = false;
  private left = false;

  created() {
    this.register();
  }

  /**
   * Listen for 'notify' events
   * @private
   */
  private register(): void {
    const registry = EventBus.getInstance().register(EventBusType.ToggleDrawer, (drawer: string) => {
      if (drawer === 'drawerRight') {
        if (this.drawerRight) {
          this.drawerRight = !this.drawerRight;
        }
      }
    });
  }

  public get authenticated(): boolean {
    return this.accountService().authenticated;
  }

  @Watch('authenticated')
  onAuthenticatedChange() {
    if (this.drawer && !this.authenticated) {
      this.drawer = false;
    }
  }
  // SPE End
}
