import { ILinkDocumentToTag } from '@/shared/model/link-document-to-tag.model';
import { ICustomOperationTrack } from '@/shared/model/custom-operation-track.model';

export interface ILinkDocumentToTagThenTrack {
  linkDocumentToTag?: ILinkDocumentToTag;
  customOperationTrack?: ICustomOperationTrack;
}

export class LinkDocumentToTagThenTrack implements ILinkDocumentToTagThenTrack {
  constructor(public linkDocumentToTag?: ILinkDocumentToTag, public customOperationTrack?: ICustomOperationTrack) {}
}
