var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "text-center" },
    [
      _c(
        "v-bottom-sheet",
        {
          attrs: { persistent: "" },
          model: {
            value: _vm.sheet,
            callback: function ($$v) {
              _vm.sheet = $$v
            },
            expression: "sheet",
          },
        },
        [
          _c(
            "v-sheet",
            { staticClass: "text-center" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mt-6",
                  attrs: { text: "", color: "error" },
                  on: {
                    click: function ($event) {
                      _vm.sheet = false
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("raptor.bottomSheet.action.close")) +
                      "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "v-list",
                { staticClass: "text-left" },
                [
                  _c("v-subheader", [
                    _vm._v(_vm._s(_vm.$t("raptor.bottomSheet.home.title"))),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.items, function (item) {
                    return _c(
                      "v-list-item",
                      { key: item.title },
                      [
                        _c(
                          "v-list-item-avatar",
                          [
                            _c(
                              "v-avatar",
                              { attrs: { size: "32px", item: "" } },
                              [_c("v-icon", { attrs: { color: item.color } })],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-list-item-title",
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(item.title) +
                                "\n            "
                            ),
                            _c(
                              "v-list-item-subtitle",
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(item.message) +
                                    "\n              "
                                ),
                                item.countDownToReconnect
                                  ? _c("go-count-down-timer", {
                                      attrs: {
                                        "initial-value": item.reconnectInterval,
                                      },
                                      on: {
                                        finish: function ($event) {
                                          return _vm.reconnect(item.id)
                                        },
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (timerCounter) {
                                              return [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "raptor.bottomSheet.reconnectInNSeconds",
                                                        { 0: timerCounter.text }
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }