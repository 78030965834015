import * as Qs from 'qs';
import { AxiosRequestConfig, AxiosStatic } from 'axios';
import { Query } from '@gv/ammo-astra/dist/services/query/query';

const baseApiUrl = '/services/fargo/api/v1/query';

export default class QueryService {
  private readonly _axios: AxiosStatic;

  private axiosConfig: AxiosRequestConfig = {
    paramsSerializer: params => Qs.stringify(params, { arrayFormat: 'repeat' }),
  };

  constructor(axios: AxiosStatic) {
    this._axios = axios;
  }

  public get axios(): AxiosStatic {
    return this._axios;
  }

  /**
   * Query fargo
   * @param slug
   * @param params
   */
  public query(slug: Query, params: { [k: string]: any }): Promise<any> {
    const config: AxiosRequestConfig = this.axiosConfig;
    config.params = { ...params };

    return new Promise<any>((resolve, reject) => {
      this._axios
        .get(`${baseApiUrl}/${slug}`, config)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
