export const fetchData = 'fetchData';
export const manufacturingPlan = 'manufacturingPlan';
export const loading = 'loading';
export const workCenter = 'workCenter';
export const rowData = 'rowData';
export const updateCompletedQuantity = 'updateCompletedQuantity';
export const complete = 'complete';
export const completeWithoutOperationTrack = 'completeWithoutOperationTrack';
export const skip = 'skip';
export const completeAll = 'completeAll';
export const resetAll = 'resetAll';
export const skipAll = 'skipAll';
export const resumeAll = 'resumeAll';
export const printLabel = 'printLabel';
export const linkDocumentToWorkOrder = 'linkDocumentToWorkOrder';
export const linkDocumentToWorkOrderThenTrack = 'linkDocumentToWorkOrderThenTrack';
export const setLinkDocumentTags = 'setLinkDocumentTags';
export const willOverTrack = 'willOverTrack';
