import axios from 'axios';

import { ISkippedOperationTrack } from '@/shared/model/skipped-operation-track.model';
import { IOperationIdentifier } from '@/shared/model/raptor/operation-identifier.model';

const baseApiUrl = 'services/fargo/api/v1/skipped-operation-tracks';

export default class SkippedOperationTrackExtendedService {
  public createBulk(entities: ISkippedOperationTrack[]): Promise<ISkippedOperationTrack[]> {
    return new Promise<ISkippedOperationTrack[]>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/bulk`, entities)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  deleteAllByOperationIdentifier(operationIdentifiers: IOperationIdentifier[]): Promise<any> {
    console.log(operationIdentifiers);

    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/bulk`, { data: operationIdentifiers })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
