import messageHandlerSupport from '@/shared/raptor/messaging/support/message/message-handler-support';
import templateParser from '@/shared/raptor/messaging/support/template-parser';
import { default as actionHandler } from '@/shared/raptor/messaging/handler/action-handler';
import { MessageType } from '@/shared/raptor/messaging/support/message/message-type';
import RaptorOperatorLandingValidationService from '@/raptor/operator-landing/service/raptor-operator-landing-validation.service';
import ApplicationContext from '@/shared/raptor/context/application-context';
import * as alertUtils from '@/shared/raptor/util/alert-utils';
import { IOperatorLandingContext, OperatorLandingContext } from '@/shared/model/raptor/messaging/support/operator-landing-context.model';
import { PublishPayload } from '@/shared/model/raptor/messaging/support/publish-payload.model';
import * as MqttHeaders from '@/shared/raptor/messaging/support/mqtt-web-socket-headers';
import { AxiosResponse } from 'axios';
import { IShopFloorDevice } from '@/shared/model/shop-floor-device.model';
import { ComponentType } from '@/shared/model/enumerations/component-type.model';
import { appLogLevel, LogLevel } from '@/shared/config/logger-spe';
import { propertyMappings } from '@/raptor/operator-landing/config/mappings';

const logLevel = appLogLevel();

/**
 * Start stage
 * @param payload
 */
function start(payload) {
  return stage(ApplicationContext.getStore().getters['workCenterDeviceStore/stages'].start, payload);
}

/**
 * Complete stage
 * @param payload
 */
function complete(payload) {
  return stage(ApplicationContext.getStore().getters['workCenterDeviceStore/stages'].complete, payload);
}

/**
 * All devices are scanned for the specified stage, and stage block get executed by the dispatcher.
 * @param name
 * @param payload
 */
function stage(name, payload) {
  const devices = ApplicationContext.getStore().getters['workCenterDeviceStore/devices'];
  devices
    .filter(device => device.hasOwnProperty('stages'))
    .forEach(device => {
      const stages = JSON.parse(device.stages['texte0']);

      stages
        .filter(stage => stage.name === name && stage.hasOwnProperty('steps'))
        .forEach(stage => {
          stage.steps.forEach(step => {
            sd(device, stage, step, payload)
              .then(res => {
                logLevel === LogLevel.DEBUG && console.debug(`${step.name} successfully executed: ${res}`);
              })
              .catch(err => {
                alertUtils.showError(`${step.name}:<br>${err.message}`);
              });
          });
        });
    });
}

/**
 * On message received, process the specified endpoint
 * @param payload
 */
function messageReceived(payload: { [k: string]: any }) {
  const message = payload;

  // The message comes from the Known broker
  if (!messageHandlerSupport.isFromKnownBroker(message)) {
    return;
  }

  if (message.deserializedPayloadError) {
    alertUtils.showError(`Malformed message received:<br>${message}`);

    //  Yakovlev will take care of malformed headers, e.g. invalid (JSON), missing headers or payload, etc.
    return;
  }

  const messagePayload = message.deserializedPayload;

  const validator = messageHandlerSupport.validator(messagePayload);
  if (validator.fails()) {
    throw new Error(RaptorOperatorLandingValidationService.errorsMessage(validator));
  }

  const devices: IShopFloorDevice[] = ApplicationContext.getStore().getters['workCenterDeviceStore/devices'];
  const device: IShopFloorDevice | undefined = devices.find(device => device.code === message.getDeviceCode());
  if (!device) {
    return;
  }

  const messageComponentType = messageHandlerSupport.getComponentType(message);
  const allowedComponentTypes = [ComponentType.HTTP, device.type];
  if (!allowedComponentTypes.includes(messageComponentType)) {
    alertUtils.showWarning(
      `Incompatible message format. Accept=[${allowedComponentTypes}] found ['${messageComponentType}']<br>${preview(message.payload, 255)}`
    );
    return;
  }
  const control: boolean = device?.controlManufacturingPlanAndWorkCenter ?? true;

  if (messageHandlerSupport.isFromMqttBroker(message) && control) {
    if (
      messagePayload.payload.manufacturingPlan !== ApplicationContext.getStore().getters['operatorLandingStore/manufacturingPlan'] ||
      messagePayload.payload.workCenter !== ApplicationContext.getStore().getters['workCenterAccountStore/user'].data.username
    ) {
      console.debug(`Received message that does not match manufacturing plan or/and work center!`);
      return;
    }
  } else if (messageHandlerSupport.isFromOpcUaBroker(message)) {
    messagePayload.payload.manufacturingPlan = ApplicationContext.getStore().getters['operatorLandingStore/manufacturingPlan'];
    if (messagePayload.payload.workCenter !== ApplicationContext.getStore().getters['workCenterAccountStore/user'].data.username) {
      console.debug(`Received message that does not match work center!`);
      return;
    }
  }

  devices
    .filter((device: IShopFloorDevice) => device.code === message.getDeviceCode() && device.hasOwnProperty('stages'))
    .forEach((device: IShopFloorDevice) => {
      const stages: { [k: string]: any } = JSON.parse(device.stages['texte0']);
      stages
        .filter(
          (stage: { [k: string]: any }) =>
            stage.name === ApplicationContext.getStore().getters['workCenterDeviceStore/stages'].messageReceived &&
            stage.hasOwnProperty('steps')
        )
        .forEach((stage: { [k: string]: any }) => {
          stage.steps
            .filter((step: { [k: string]: any }) => step.name === messagePayload.headers.endpoint)
            .forEach((step: { [k: string]: any }) => {
              sd(device, stage, step, payload)
                .then(() => {
                  logLevel === LogLevel.DEBUG && console.debug(`Step ${step.name} execution: succeeded.`);
                })
                .catch(err => {
                  console.error(`Step ${step.name} execution: failed.`, err);
                  alertUtils.showError(`${step.name}:<br>${err.message}`);
                  actionHandler
                    .publish(device, stage, step, MessageType.RESPONSE, messageHandlerSupport.prepareResponse(messagePayload, err))
                    .catch(publishErr => {
                      console.error(`Publish ${step.name} : failed.`, err);
                      alertUtils.showError(`${step.name}:<br>${publishErr.message}`);
                    });
                });
            });
        });
    });
}

/**
 * Step dispatcher, a single task. Fundamentally, a step tells Raptor what to do at a particular point in time (or
 * "step" in the process).
 * @param device
 * @param stage
 * @param step
 * @param payload
 */
function sd(
  device: IShopFloorDevice,
  stage: { [k: string]: any },
  step: { [k: string]: any },
  payload: { [k: string]: any }
): Promise<any> {
  logLevel === LogLevel.DEBUG && console.debug(`sd : device=${device.code}, stage=${stage.name}, step=${step.name}, payload=`, payload);

  return new Promise<any>((resolve, reject) => {
    if (step.hasOwnProperty('filter')) {
      const filterFunction = (filter: string, scope: { [k: string]: any }) => new Function('return `${' + filter + '}`').call(scope);
      const scope = { device };
      if (!(filterFunction(step.filter, scope) === 'true')) {
        logLevel === LogLevel.DEBUG && console.debug(`Step '${step.name}' skipped. Filter [${step.filter}] return false`);
        resolve(undefined);
        return;
      }
    }

    let action: () => Promise<any>;
    switch (step.name) {
      case ApplicationContext.getStore().getters['workCenterDeviceStore/steps'].sendMqttMessage:
        const message = {
          headers: { [MqttHeaders.PUBLISH]: null },
          deserializedPayload: { headers: {}, payload: { ...payload } },
        };
        const context = new OperatorLandingContext(message, payload.data);
        const publishPayload = new PublishPayload(context, getEmptyResponse());
        action = () => actionHandler.publish(device, stage, step, MessageType.REQUEST, publishPayload);
        break;

      case ApplicationContext.getStore().getters['workCenterDeviceStore/steps'].linkDocumentToWorkOrderThenTrack:
        action = () =>
          actionHandler.linkDocumentToWorkOrderThenTrack(device, stage, step, getOperatorLandingContext(device, stage, step, payload));
        break;

      case ApplicationContext.getStore().getters['workCenterDeviceStore/steps'].completeWithoutOperationTrack:
        action = () => {
          return actionHandler.completeWithoutOperationTrack(device, stage, step, getOperatorLandingContext(device, stage, step, payload));
        };
        break;

      case ApplicationContext.getStore().getters['workCenterDeviceStore/steps'].operationTrack:
      case ApplicationContext.getStore().getters['workCenterDeviceStore/steps'].operationTrackWithoutContext:
        action = () => actionHandler.operationTrack(device, stage, step, getOperatorLandingContext(device, stage, step, payload));
        break;

      case ApplicationContext.getStore().getters['workCenterDeviceStore/steps'].printLabel:
        action = () => actionHandler.printLabel(device, stage, step, getOperatorLandingContext(device, stage, step, payload));
        break;

      default:
        reject(new Error(`Not implemented step action ${step.name} for device ${device.code} stage ${stage.name}`));
        return;
    }

    return action()
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
}

/**
 * Utility function for build the default context where there are none
 *
 * @returns {any}
 */
function noContext() {
  return {
    workCenter: ApplicationContext.getStore().getters['workCenterAccountStore/user'].data.username,
    manufacturingSite: ApplicationContext.getStore().getters['workCenterAccountStore/user'].data.site,
    manufacturingPlan: ApplicationContext.getStore().getters['operatorLandingStore/manufacturingPlan'],
  };
}

/**
 * Enhances the context of the message payload by populating missing properties
 * from the application context's store data. Specifically, it checks for the presence
 * of certain properties in the payload and, if missing, fills them in with values
 * retrieved from the `operatorLandingStore` in the global `ApplicationContext`.
 *
 * @param messagePayload - The payload object that will be enriched with
 *                         additional properties if they are missing.
 * @param messagePayload.payload - The actual data within the payload
 *                                 that needs enrichment.
 */
function enrichContext(messagePayload: { [k: string]: any }): void {
  const current = ApplicationContext.getStore().getters['operatorLandingStore/current'];
  const payload = messagePayload.payload;

  if (!payload.hasOwnProperty('manufacturingPlan')) {
    payload['manufacturingPlan'] = ApplicationContext.getStore().getters['operatorLandingStore/manufacturingPlan'];
  }
  Object.entries(propertyMappings).forEach(([propertyName, rowDataKey]) => {
    if (!payload.hasOwnProperty(propertyName) || !payload[propertyName]) {
      payload[propertyName] = current.rowData[rowDataKey];
    }
  });
}

function getOperatorLandingContext(
  device: IShopFloorDevice,
  stage: { [k: string]: any },
  step: { [k: string]: any },
  payload: { [k: string]: any }
): IOperatorLandingContext {
  const validationService = new RaptorOperatorLandingValidationService(ApplicationContext.getI18n());
  const message = payload;
  const deviceTopic = templateParser.fill(device.output, { device, payload: noContext() });
  const messageTopic = message.getTopic();

  // If the message does not match device subscribe topic
  if (!messageHandlerSupport.match(deviceTopic, messageTopic)) {
    return;
  }

  if (!step.hasOwnProperty('request')) {
    throw new Error(`Property key request not found in step action ${step.action} for device ${device.code} stage ${stage.name}`);
  }
  const request = step.request;

  if (!request.hasOwnProperty('parameters')) {
    throw new Error(
      `Property key parameters not found in the step request object action ${step.action} for device ${device.code} stage ${stage.name}`
    );
  }

  const messagePayload = message.deserializedPayload;
  enrichContext(messagePayload);

  const validator = validationService.step(step.name, messagePayload.payload);
  if (validator.fails()) {
    throw new Error(RaptorOperatorLandingValidationService.errorsMessage(validator));
  }
  const parameters = JSON.parse(templateParser.fill(request.parameters, messagePayload));
  const data = ApplicationContext.getStore().getters['operatorLandingStore/byOrderNumberAndOperationAndOperationSplit'](parameters);

  if (data === null) {
    const contextId = Object.keys(parameters)
      .map(i => `${parameters[i]}`)
      .reduce((a, c) => (a = `${a}~${c}`));
    const errorMessage = `Failed to retrieve context for ${contextId}`;
    throw new Error(errorMessage);
  }
  return new OperatorLandingContext(payload, { ...data });
}

function getEmptyResponse(): AxiosResponse {
  return { data: null, status: null, statusText: null, headers: null, config: null, request: null };
}

function endsWith(str: string, suffix: string): boolean {
  return str.indexOf(suffix, str.length - suffix.length) !== -1;
}

function preview(str: string, length: number): string {
  const minimumLength = 10;
  if (!str) {
    return '';
  }
  if (length < minimumLength) {
    throw new Error(`Illegal argument exception length cannot be less ${minimumLength}`);
  }
  return str.length <= length ? str : str.substring(0, length);
}

export default {
  messageReceived,
  start,
  complete,
};
